import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import clsx from "clsx";

import {
  selectBrandGroupByBrandMap,
  selectBrandImages,
  selectBrandLabels,
} from "../../store/store/storeSlice";
import CustomText from "./CustomText";

interface Props {
  brandOrBrandGroup: string;
  isBrandGroup?: boolean;
  fallbackLabel?: string;
  invert?: boolean;
  className?: string;
}

const BrandImage = ({
  brandOrBrandGroup,
  isBrandGroup = false,
  fallbackLabel,
  invert = false,
  className = "",
}: Props): JSX.Element | null => {
  const brandImages = useSelector(selectBrandImages);
  const labels = useSelector(selectBrandLabels);
  const brandGroups = useSelector(selectBrandGroupByBrandMap);

  const [isLoading, setIsloading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const brandgroup = isBrandGroup
    ? brandOrBrandGroup // if we already have a brand group, use it
    : brandGroups[brandOrBrandGroup]; // otherwise get the brand group by brand

  const srcUrl = brandImages[brandgroup]; // get image url for that brand group

  return brandgroup ? (
    <Container>
      {srcUrl && !isError && (
        <img
          className={className + clsx(invert && " invert", isLoading && " hide")}
          src={srcUrl}
          onLoad={() => setIsloading(false)}
          onError={() => setIsError(true)}
        />
      )}
      {(isLoading || isError) && (
        <FallbackContainer>
          <CustomText as="p">{fallbackLabel ?? labels[brandgroup]}</CustomText>
        </FallbackContainer>
      )}
    </Container>
  ) : null;
};

const Container = styled.div`
  img {
    width: 100%;

    .hide {
      display: none;
    }
  }

  .invert {
    filter: invert(1);
  }
`;

const FallbackContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0.5rem; */

  p {
    text-align: center;
  }
`;

export default BrandImage;
