import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useCanRender from "../../../../../hooks/useCanRender";
import useTranslation from "../../../../../hooks/useTranslation";

import { resetRxState } from "../../../../../store/rx/rxSlice";
import {
  selectActiveDoor,
  selectCanBuyModelsGlobal,
  selectCanBuySparepartsGlobal,
  selectIsBackOfficeUser,
  selectIsSubuser,
  selectMultiDoors,
} from "../../../../../store/user/userSlice";
import {
  NavbarSection,
  NavbarSectionLink,
} from "../../../../pages/my-account/account-navbar/navbarSections";
import CustomText from "../../../../styled-UI/CustomText";
import ConditionalRender from "../../../../widgets/ConditionalRender";
import useNavbarSectionLinkHandler from "../useNavbarSectionLinkHandler";
import { ReactComponent as LinkIcon } from "../../../../../assets/icons/ext-link.svg";
import { HeaderListContainer } from "../categories-menu/category-item/MenuColumn";
import NewTag from "../../../../widgets/tutorial-pills/new-tag/NewTag";
import { externalReferenceNewTag } from "../../../../../utils/cmsUtils";

interface Props {
  service: NavbarSection;
  hideServices: boolean;
}

const ServiceSectionColumn = ({ service, hideServices = false }: Props): JSX.Element | null => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const canRender = useCanRender();

  const { handleNavbarSectionLinkClick } = useNavbarSectionLinkHandler();
  const canBuyModelsGlobal = useSelector(selectCanBuyModelsGlobal);
  const canBuySparepartsGlobal = useSelector(selectCanBuySparepartsGlobal);
  const isSubuser = useSelector(selectIsSubuser);
  const mainDoor = useSelector(selectActiveDoor);
  const multidoors = useSelector(selectMultiDoors);

  const [showColumn, setShowColumn] = useState<boolean>(false);

  const checkPrivilege = (link?: NavbarSectionLink) => {
    if (isSubuser && link?.exclusivePrivilege) {
      if (
        mainDoor &&
        multidoors.some((door) => mainDoor.orgentityName === door.orgentityName) &&
        canRender(link?.privilege, mainDoor)
      )
        setShowColumn(true);
    } else {
      if (!link?.privilege || canRender(link?.privilege)) setShowColumn(true);
    }
  };

  useEffect(() => {
    service.links?.forEach((value: NavbarSectionLink) => {
      if (!showColumn) checkPrivilege(value);
    });
  }, []);

  const goToService = (link: NavbarSectionLink) => {
    handleNavbarSectionLinkClick(link);

    if (link.title.toUpperCase().includes("RX")) dispatch(resetRxState());
  };

  const isServiceHidden = (link: NavbarSectionLink): boolean => {
    if (isSubuser && link.exclusivePrivilege) {
      if (
        mainDoor &&
        multidoors.some((door) => mainDoor.orgentityName === door.orgentityName) &&
        !canRender(link.privilege, mainDoor)
      )
        return true;
    }
    if (
      link.title === "RX_PRESCRIPTION_TITLE" ||
      link.title === "POS_SOURCE_FILE_TITLE" ||
      link.title === "PRICE_LIST_TITLE" ||
      link.title === "ITEMS_MASTER_DATA_TITLE"
    ) {
      if (link.title === "PRICE_LIST_TITLE" && !canRender("SUGGESTED_RETAIL_PRICE")) {
        return true;
      }
      if (!canBuyModelsGlobal || hideServices) {
        return true;
      }
    }

    if (link.title === "SPAREPARTS_TITLE" || link.title === "WARRANTY_TITLE") {
      if (!canBuySparepartsGlobal || hideServices) {
        return true;
      }
    }

    return false;
  };

  if (showColumn) {
    return (
      <Container>
        <HeaderListContainer>
          <CustomText
            fontSizePx={18}
            as="span"
            marginBottomPx={20}
            font="font-medium"
            data-element-id={`MainNav_Services_${service.sectionTitle}`}
          >
            {translateLabel(service.sectionTitle)}
          </CustomText>
          <NewTag position={externalReferenceNewTag[service.sectionTitle]}></NewTag>
        </HeaderListContainer>

        <UrlContainer>
          {service.links?.map((value: NavbarSectionLink) => {
            const content = (
              <LinkElement>
                <CustomText
                  key={value.title}
                  fontSizePx={14}
                  as="button"
                  font="font-regular"
                  lineHeightPx={28}
                  onClick={() =>
                    value.identifier === "LENSES_TRADE_MARKETING"
                      ? window.open("https://myessilor.it/login/", "_blank")
                      : goToService(value)
                  }
                  data-element-id={`MainNav_Services_${service.sectionTitle}_${value.dataElementId}`}
                >
                  {translateLabel(value.title)}
                </CustomText>
                {value.identifier === "LENSES_TRADE_MARKETING" ||
                  (value.identifier === "DIGITAL_DISCOUNT_VOUCHER" && <LinkIcon />)}
              </LinkElement>
            );

            return value.privilege ? (
              <ConditionalRender
                privilege={value.privilege}
                key={value.title}
                onlySubuser={value.onlySubuser}
              >
                {!isServiceHidden(value) && content}
              </ConditionalRender>
            ) : (
              content
            );
          })}
        </UrlContainer>
      </Container>
    );
  }
  return null;
};

const Container = styled.div`
  flex: 1;
  max-width: 16.5rem;
  margin-right: 1rem;

  &:last-child {
    margin-right: unset;
    width: 17rem;
  }
`;

const UrlContainer = styled.div`
  text-align: left;
`;

const LinkElement = styled.div`
  display: flex;
  & > button:hover {
    font-family: ${(props) => props.theme.fonts.fontBold};
  }
  & > svg {
    width: 20px;
    height: 20px;
    align-self: center;
  }
`;

export default ServiceSectionColumn;
