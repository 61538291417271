import { useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";
import { MostUsedChipCatalogueLocal } from "../interfaces/mostUsedInterfaces";
import { LensItemEssilor } from "../store/store/storeInterfaces";
import { selectLensesEssilorMenu } from "../store/store/storeSlice";
import { selectLocale, selectStoreIdentifier } from "../store/user/userSlice";
import { normalizeChipLabel, sortChips } from "../utils/mostUsedUtils";
import useCanRender from "./useCanRender";
import useTranslation from "./useTranslation";

const useMostUsedLenses = (): MostUsedChipCatalogueLocal[] => {
  const lensesEssilorMenu = useSelector(selectLensesEssilorMenu);
  const canRender = useCanRender();
  const storeIdentifier = useSelector(selectStoreIdentifier);
  const currentLocale = useSelector(selectLocale);
  const { translateLabel } = useTranslation();

  const canRenderItems = (): LensItemEssilor[] => {
    const lensItemsEssilor: LensItemEssilor[] = [];
    if (canRender("LENS_DIGITAL_CATALOGUE_ORDER_ENTRY") || canRender("RX"))
      lensItemsEssilor.push({
        id: "LENS_CONFIGURATOR",
        name: "LENS_CONFIGURATOR",
        type: "LENS_CONFIGURATOR",
        linkString: canRender("LENS_DIGITAL_CATALOGUE_ORDER_ENTRY")
          ? "/rx-prescription?rxFlow=Essilor"
          : canRender("RX")
          ? "/rx-prescription?rxFlow=Authentics"
          : "",
        displayOrder: 0,
      });
    if (canRender("LENS_DIGITAL_CATALOGUE"))
      lensItemsEssilor.push({
        id: "DIGITAL_CATALOGUE",
        name: "DIGITAL_CATALOGUE",
        type: "DIGITAL_CATALOGUE",
        linkString: "/digital-catalog",
        displayOrder: 1,
      });

    if (canRender("LENS_DIGITAL_CATALOGUE_ORDER_ENTRY"))
      lensItemsEssilor.push({
        id: "SAVED_ORDERS",
        name: "SAVED_ORDERS",
        type: "SAVED_ORDERS",
        linkString: "/essilor-draft-orders",
        displayOrder: 2,
      });

    return lensItemsEssilor;
  };

  const [essilorOptions, setEssilorOptions] = useState<LensItemEssilor[]>([]);

  useEffect(() => {
    if (lensesEssilorMenu) {
      const collectedItems: LensItemEssilor[] = canRenderItems();

      lensesEssilorMenu.forEach((column) => {
        const matchingItems = column.items.filter(
          (item) =>
            (item.id === "PROGRESSIVE_LENSES" || item.id === "SINGLE_VISION_LENSES") &&
            (canRender("LENS_DIGITAL_CATALOGUE_ORDER_ENTRY") ||
              canRender("LENS_DIGITAL_CATALOGUE") ||
              canRender("RX_LENS_ONLY") ||
              canRender("RX_FRAME_TO_COME") ||
              canRender("RX_COMPLETE_JOB"))
        );
        collectedItems.push(...matchingItems);
      });
      setEssilorOptions(collectedItems);
    }
  }, [lensesEssilorMenu]);

  return useMemo(() => {
    const mostUsedChipsLenses: MostUsedChipCatalogueLocal[] = [];
    if (essilorOptions) {
      essilorOptions?.map((item) => {
        mostUsedChipsLenses.push({
          label: translateLabel(normalizeChipLabel(item.id)),
          name: item.id,
          type: "Lenses",
          url: `/${storeIdentifier}/${currentLocale}${item.linkString}`,
          catalogueInfo: {
            facets: [{ facetName: item.name, facetValue: item.name }],
            menuCategory: "LENSES",
          },
        });
      });
    }
    return sortChips(mostUsedChipsLenses) as MostUsedChipCatalogueLocal[];
  }, [essilorOptions]);
};

export default useMostUsedLenses;
