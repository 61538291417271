import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import clsx from "clsx";

import {
  toggleVideoPopup,
  selectPopupVideoSettings,
  selectPopupPersonification,
  togglePopupPersonification,
  selectPopupServiceRequest,
  togglePopupServiceRequest,
  selectPopupLpVariants,
  togglePopupLpVariants,
  resetLpVariantsList,
  selectCurrentLang,
} from "../../store/store/storeSlice";
import {
  selectIsBackOfficeUser,
  selectIsLegalTermsAccepted,
  selectIsMultidoor,
  selectIsSubuser,
  selectSrpOptInChoice,
  setPostPersonificationStatus,
} from "../../store/user/userSlice";
import { selectPrintReturnParcel } from "../../store/aftersales/aftersalesSlice";
import WrapperLayout from "../layouts/WrapperLayout";
import MultiDoorBanner from "../widgets/MultiDoorBanner";
import WeAgreeBanner from "../widgets/we-agree-banner/WeAgreeBanner";
import ServiceRequestPopup from "../widgets/service-request-popup/ServiceRequestPopup";
import SapDecouplingConditionalPopup from "../widgets/SapDecouplingConditionalPopup";
import ConditionalRender from "../widgets/ConditionalRender";
import ErrorBoundary from "../widgets/ErrorBoundary";
import LpVariantsPopup from "../widgets/landing-page-widgets/landing-page-variants-popup/LpVariantsPopup";
import UserNotMigratedPopup from "../widgets/UserNotMigratedPopup";
import CookiesBanner from "../widgets/cookies/CookiesBanner";
import DuplicatedEmailPopup from "../widgets/DuplicatedEmailPopup";
import { MemoizedBreadcrumbSection } from "../widgets/breadcrumb-section/BreadcrumbSection";
import PersonificationPopup from "../widgets/personification-popup/PersonificationPopup";

import VideoPopup from "../styled-UI/VideoPopup";
import ReturnParcelData from "../pages/return-parcel-data/ReturnParcelData";
import HeaderComponent from "./header/Header";
import AccountingPinPopup from "./header/AccountingPinPopup";
import { useNavbarAutoHide } from "./header/navbar/useNavbarAutoHide";
import { usePageContentDimensions } from "./usePageContentDimensions";
import { MemoizedFooterComponent } from "./Footer";
import ADTPopup from "../widgets/ADT/ADT-first-time-popup/ADTPopup";
import SRPOptInPopup from "../widgets/SRP-opt-in-popup/SRPOptInPopup";
import useCanRender from "../../hooks/useCanRender";
import QualtricsSurvey from "../widgets/QualtricsSurvey";
import ExploreFeatures from "../widgets/tutorial-pills/explore-features/ExploreFeatures";
import LegalRequirementsPopup from "../widgets/legal-requirements-popup/LegalRequirementsPopup";
import { selectTermsConditionDocument } from "../../store/documents/documentsSlice";

interface Props {
  children: any;
}

const AppLayout = ({ children }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const canRender = useCanRender();
  const isMultiDoor = useSelector(selectIsMultidoor);
  const isBackofficeUser = useSelector(selectIsBackOfficeUser);
  const srpOptInChoice = useSelector(selectSrpOptInChoice);

  const termsConditionDoc = useSelector(selectTermsConditionDocument);
  const isLegalTermsAccepted = useSelector(selectIsLegalTermsAccepted);

  const [qualtricsRendered, setQualtricsRendered] = useState<boolean>(false);
  const isSubuser = useSelector(selectIsSubuser);

  const { hideNavbar } = useNavbarAutoHide();
  const { refContent, heightContent, widthContent, scaleContent } = usePageContentDimensions();

  //parcel
  const printReturnParcel = useSelector(selectPrintReturnParcel);

  //popup
  const popupVideoSetting = useSelector(selectPopupVideoSettings);
  const popupPersonification = useSelector(selectPopupPersonification);
  const popupServiceRequest = useSelector(selectPopupServiceRequest);
  const popupLpVariants = useSelector(selectPopupLpVariants);
  const [popupSrpOptIn, setPopupSrpOptIn] = useState<boolean>(false);

  useEffect(() => {
    //Se SRP_OPT_IN_SECTION (combinazione privilegi + essere main user) + è primo login => utente deve vedere popup
    if (srpOptInChoice === "" && canRender("SRP_OPT_IN_SECTION")) {
      //Do we need !canRender("SRP_OP_IN") ???
      setPopupSrpOptIn(true);
    }
    //Se manca una delle 3 condizioni sopra => utente non vede popup
    else {
      setPopupSrpOptIn(false);
    }
  }, [srpOptInChoice]);

  const closePopupPersonification = () => {
    dispatch(togglePopupPersonification({ open: false }));
    dispatch(setPostPersonificationStatus("IDLE"));
  };

  const onModalClose = () => {
    dispatch(togglePopupLpVariants({ open: false, productCode: null }));
    dispatch(resetLpVariantsList());
  };

  const exploreFeatures = (): JSX.Element => {
    try {
      return <ExploreFeatures />;
    } catch (err) {
      return <></>;
    }
  };

  const isLandingPage = window.location.href.includes("contents");
  const hasDarkBackground = location.pathname.includes("plp-stars");
  const isSparePartsPage = location.pathname.includes("spare-parts");
  const isWarrantyPage = /warranty/i.test(location.pathname) && !/account/i.test(location.pathname);

  return (
    <BodyContainer className={clsx(isLandingPage && "body-landing")}>
      {exploreFeatures()}
      <MultiDoorBanner hideHeaderAnim={hideNavbar} />

      <ErrorBoundary>
        <HeaderComponent isMultiDoor={isMultiDoor} hideHeaderAnim={hideNavbar} />
      </ErrorBoundary>

      <Content
        id="page-content"
        ref={refContent}
        className={clsx(
          "print-ready",
          isMultiDoor && "content-multidoor",
          hideNavbar && "content-no-header",
          hasDarkBackground && "content-dark",
          isWarrantyPage && "content-secondary-gray",
          isSparePartsPage && "content-secondary-gray",
          isLandingPage && "content-landing"
        )}
        height={heightContent}
        width={widthContent}
        scale={scaleContent}
      >
        <WrapperLayout>
          <MemoizedBreadcrumbSection />
        </WrapperLayout>

        {children}
      </Content>

      <VideoPopup
        isOpen={popupVideoSetting.open}
        top={popupVideoSetting.top}
        close={() => dispatch(toggleVideoPopup({ open: false, src: null, top: null }))}
        srcVideo={popupVideoSetting.src}
      />

      <PersonificationPopup isOpen={popupPersonification.open} close={closePopupPersonification} />

      <AccountingPinPopup />

      {popupServiceRequest.open && popupServiceRequest.requestType && (
        <ServiceRequestPopup
          data={popupServiceRequest}
          close={() =>
            dispatch(
              togglePopupServiceRequest({
                open: false,
                requestType: null,
                addressOf: null,
                addressDetails: null,
                orderIdentifier: null,
                shippingCode: null,
                b2bOrderIdentifier: null,
              })
            )
          }
        />
      )}

      {/* POPUP ORDERS
        1 - Terms of use acceptance 
        2 - SRP Opt-in
        3 - We Agree
        4 - Customer satisfaction
        5 - mail duplicate
        6 - tutorial pills
      */}

      <SapDecouplingConditionalPopup />
      <UserNotMigratedPopup />
      <DuplicatedEmailPopup />

      {process.env.NODE_ENV !== "development" && (
        <QualtricsSurvey setQualtricsRendered={() => setQualtricsRendered(true)} />
      )}

      {/* We use the qualtricsReady variable to understand when the customer survey
        has been rendered because the the SRP popup should appear as the last one
      */}

      {qualtricsRendered && !isSubuser && (
        <ConditionalRender privilege="WE_AGREE">
          <WeAgreeBanner />
        </ConditionalRender>
      )}

      {!isBackofficeUser && popupSrpOptIn && qualtricsRendered && (
        <SRPOptInPopup isOpen={true} close={() => setPopupSrpOptIn(false)} />
      )}

      {!isBackofficeUser && !isSubuser && !isLegalTermsAccepted && (
        <LegalRequirementsPopup isOpen={true} currentTermsDoc={termsConditionDoc} />
      )}

      <ADTPopup />

      {popupLpVariants.open && popupLpVariants.productCode && (
        <LpVariantsPopup
          productCode={popupLpVariants.productCode}
          isOpen={popupLpVariants.open}
          close={onModalClose}
        />
      )}

      <CookiesBanner />

      {/* DOM element where an error popup can be rendered through a portal. List of components using it: */}
      {/* NavbarMenuLinkButton.tsx */}
      <div id="error-popup-portal"></div>

      <MemoizedFooterComponent />

      <HiddenContent className="print-section">
        {printReturnParcel.open && printReturnParcel.warrantyId && (
          <ReturnParcelData id={printReturnParcel.warrantyId} />
        )}
      </HiddenContent>
    </BodyContainer>
  );
};

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.background.light};
  min-height: 100%;
  overflow: hidden;

  &.body-landing {
    /* overflow: auto;
    min-width: 1440px; */
  }
`;

const Content = styled.div<{ width: number; height: number; scale: number }>`
  flex: 1 0 auto;
  margin-top: ${(props) => props.theme.headerHeightRem + "rem"};
  transition: margin-top 0.3s;

  &.content-dark {
    background-color: ${(props) => props.theme.palette.primary};
  }

  &.content-secondary-gray {
    background-color: ${(props) => props.theme.palette.gray.secondaryGray};
  }

  margin-top: ${(props) =>
    props.theme.headerHeightRem + props.theme.smallScreenNavbarHeightRem + "rem"};

  &.content-no-header {
    margin-top: 0;
  }

  &.content-multidoor {
    margin-top: ${(props) =>
      props.theme.headerHeightRem + props.theme.multidoorBannerHeightRem + "rem"};

    margin-top: ${(props) =>
      props.theme.headerHeightRem +
      props.theme.smallScreenNavbarHeightRem +
      props.theme.multidoorBannerHeightRem +
      "rem"};

    &.content-no-header {
      margin-top: 0;
    }
  }

  &.content-landing {
    /* @media ${(props) => props.theme.queries.md} {
      //transform: scale(${(props) => props.scale});
      transform-origin: top left;
      //margin-bottom: ${(props) => props.height * props.scale - props.height}px;
    } */
  }
`;

const HiddenContent = styled.div`
  display: none;
`;

export default AppLayout;
