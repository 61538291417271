import React from "react";
import clsx from "clsx";
import styled from "styled-components/macro";
import IconButton from "./IconButton";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import { ReactComponent as ArrowDisabled } from "../../assets/icons/arrow-disabled.svg";
import { ReactComponent as ArrowHeadRight } from "../../assets/icons/arrowhead-right-icon.svg";
import { ReactComponent as ArrowHeadLeft } from "../../assets/icons/arrowhead-left-icon.svg";
import CarouselDotBar from "./CarouselDotBar";
import { NavigationType } from "./Carousel";
import { getHighlightsSlidesToShow } from "../widgets/highlights-section/HighlightsCarousel";
import { useBreakpoint } from "../../assets/theme/breakpoint";

interface Props {
  currentSlide: number;
  mode?: "dark" | "default" | "white";
  slidesToScroll: number;
  navigationType: NavigationType;
  lateralNavigationCarousel?: boolean;
  currentPage: number;
  lastPage: number;
  changeSlide: (arg: number, dir?: string) => void;
  "data-element-id"?: string;
  "data-description"?: string;
  enableDataDescriptionNavigation?: boolean;
  fadingSlides?: boolean;
}

const CarouselNavBar = ({
  slidesToScroll,
  changeSlide,
  currentSlide,
  navigationType,
  currentPage,
  lastPage,
  "data-element-id": dataElementId,
  "data-description": dataDescription,
  enableDataDescriptionNavigation,
  mode = "default",
  fadingSlides,
}: Props): JSX.Element => {
  const breakpoints = useBreakpoint();

  const NavigationClasses = clsx(
    navigationType === "lateral" && "lateral-navigation",
    navigationType === "central" && "central-navigation",
    navigationType === "default" && "homepage-navigation",
    navigationType === "multidoor-add-size" && "multidoor-add-size",
    navigationType === "lp-banner" && "lp-banner",
    navigationType === "minimal-arrow" && "up-right-navigation",
    navigationType === "tutorialpills-popup" && "tutorialpills-popup"
  );

  const SpacerClasses = clsx(navigationType === "central" && "central-navigation");

  const renderNavigationType = () => {
    switch (navigationType) {
      case "default":
        return (
          <>
            <ButtonSpacer>
              <ElevatedButton>
                <IconButton
                  size="lg"
                  onClick={() => changeSlide(currentSlide - slidesToScroll)}
                  disabled={currentPage === 0}
                  icon={<Arrow />}
                  isCarousel
                  {...(dataElementId ? { "data-element-id": dataElementId } : {})}
                  {...(enableDataDescriptionNavigation ? { "data-description": "arrow" } : {})}
                />
              </ElevatedButton>
            </ButtonSpacer>
            <ElevatedButton>
              <IconButton
                size="lg"
                onClick={() => changeSlide(currentSlide + slidesToScroll)}
                disabled={currentPage === lastPage}
                icon={<Arrow />}
                isCarousel
                {...(dataElementId ? { "data-element-id": dataElementId } : {})}
                {...(enableDataDescriptionNavigation ? { "data-description": "arrow" } : {})}
              />
            </ElevatedButton>
          </>
        );
      case "minimal-arrow":
        return (
          <>
            <ButtonSpacer>
              <ElevatedButton>
                <IconButton
                  size="lg"
                  type={"no-background-primary"}
                  onClick={() => changeSlide(currentSlide - slidesToScroll)}
                  disabled={currentPage === 0}
                  icon={<Arrow />}
                  isCarousel
                  {...(dataElementId ? { "data-element-id": dataElementId } : {})}
                  {...(enableDataDescriptionNavigation ? { "data-description": "arrow" } : {})}
                />
              </ElevatedButton>
            </ButtonSpacer>
            <ElevatedButton>
              <IconButton
                size="lg"
                type={"no-background-primary"}
                onClick={() => changeSlide(currentSlide + slidesToScroll)}
                disabled={currentPage === lastPage}
                icon={<Arrow />}
                isCarousel
                {...(dataElementId ? { "data-element-id": dataElementId } : {})}
                {...(enableDataDescriptionNavigation ? { "data-description": "arrow" } : {})}
              />
            </ElevatedButton>
          </>
        );
      case "lateral":
        return (
          <>
            {currentPage === 0 ? null : (
              <PrevButtonLateral className="carousel-navbar-button-lateral">
                <IconButton
                  size="lg"
                  type="white-no-border"
                  onClick={() => changeSlide(currentSlide - slidesToScroll)}
                  icon={<ArrowHeadLeft />}
                  data-element-id={dataElementId}
                  data-description={dataDescription}
                />
              </PrevButtonLateral>
            )}
            {currentPage === lastPage ? null : (
              <NextButtonLateral className="carousel-navbar-button-lateral">
                <IconButton
                  size="lg"
                  type="white-no-border"
                  onClick={() => changeSlide(currentSlide + slidesToScroll)}
                  icon={<ArrowHeadRight />}
                  data-element-id={dataElementId}
                  data-description={dataDescription}
                />
              </NextButtonLateral>
            )}
          </>
        );
      case "central":
        return (
          <>
            <ButtonSpacer className={SpacerClasses}>
              <IconButton
                type={
                  mode === "default"
                    ? "white-with-border"
                    : mode === "dark"
                    ? "primary"
                    : "white-no-border"
                }
                size="md"
                onClick={() => changeSlide(currentSlide - slidesToScroll)}
                disabled={currentPage === 0}
                icon={<ArrowHeadRight />}
                {...(dataElementId ? { "data-element-id": dataElementId } : {})}
              />
            </ButtonSpacer>
            <DotsSection>
              <CarouselDotBar
                lastPage={lastPage}
                currentPage={currentPage}
                changeSlide={changeSlide}
                navigationType={navigationType}
                backgroundDark={mode === "white"}
              />
            </DotsSection>
            <IconButton
              type={
                mode === "default"
                  ? "white-with-border"
                  : mode === "dark"
                  ? "primary"
                  : "white-no-border"
              }
              size="md"
              onClick={() => changeSlide(currentSlide + slidesToScroll)}
              disabled={currentPage === lastPage}
              icon={<ArrowHeadRight />}
              {...(dataElementId ? { "data-element-id": dataElementId } : {})}
            />
          </>
        );
      case "multidoor-add-size":
        return (
          <>
            <ButtonSpacer className={SpacerClasses}>
              <IconButton
                type="white-with-border"
                size="md"
                onClick={() => changeSlide(currentSlide - slidesToScroll)}
                disabled={currentPage === 0}
                icon={<ArrowHeadRight />}
                {...(dataElementId ? { "data-element-id": dataElementId } : {})}
              />
            </ButtonSpacer>
            <ButtonSpacer className={SpacerClasses}>
              <CarouselDotBar
                lastPage={lastPage}
                currentPage={currentPage}
                changeSlide={changeSlide}
                navigationType={navigationType}
              />
            </ButtonSpacer>
            <IconButton
              type="white-with-border"
              size="md"
              onClick={() => changeSlide(currentSlide + slidesToScroll)}
              disabled={currentPage === lastPage}
              icon={<ArrowHeadRight />}
              {...(dataElementId ? { "data-element-id": dataElementId } : {})}
            />
          </>
        );
      case "highlights":
        const slidesToShow = getHighlightsSlidesToShow(breakpoints["md"] ? "md" : "bg", lastPage);
        const hideArrows = slidesToShow >= lastPage;

        return (
          <>
            <div className="highlight-button button-prev">
              <IconButton
                size="lg"
                type="no-background-white"
                onClick={() => changeSlide(currentSlide - slidesToScroll)}
                icon={!hideArrows ? <Arrow /> : <></>}
                data-element-id={dataElementId}
                data-description={dataDescription}
              />
            </div>
            <div className="highlight-button">
              <IconButton
                size="lg"
                type="no-background-white"
                onClick={() => changeSlide(currentSlide + slidesToScroll)}
                icon={!hideArrows ? <Arrow /> : <></>}
                data-element-id={dataElementId}
                data-description={dataDescription}
              />
            </div>
          </>
        );
      case "lp-banner":
        return (
          <>
            {currentPage === 0 ? (
              <div></div>
            ) : (
              <LpBannerButton>
                <IconButton
                  size="lg"
                  type="primary"
                  onClick={() => changeSlide(currentSlide - slidesToScroll)}
                  icon={<ArrowHeadLeft />}
                />
              </LpBannerButton>
            )}
            {currentPage === lastPage ? (
              <div></div>
            ) : (
              <LpBannerButton>
                <IconButton
                  size="lg"
                  type="primary"
                  onClick={() => changeSlide(currentSlide + slidesToScroll)}
                  icon={<ArrowHeadRight />}
                />
              </LpBannerButton>
            )}
          </>
        );
      case "accordion":
        return (
          <>
            <ButtonSpacer className={SpacerClasses}>
              <IconButton
                type="white-with-border"
                size="md"
                onClick={() => changeSlide(currentSlide - slidesToScroll)}
                disabled={currentPage === 0}
                icon={<ArrowHeadRight />}
                {...(dataElementId ? { "data-element-id": dataElementId } : {})}
              />
            </ButtonSpacer>
            <IconButton
              type="white-with-border"
              size="md"
              onClick={() => changeSlide(currentSlide + slidesToScroll)}
              disabled={currentPage === lastPage}
              icon={<ArrowHeadRight />}
              {...(dataElementId ? { "data-element-id": dataElementId } : {})}
            />
          </>
        );
      case "popup":
        return (
          <>
            <ButtonSpacer className={SpacerClasses}>
              <IconButton
                type="white-with-border"
                size="md"
                onClick={() => changeSlide(currentSlide - slidesToScroll, "prev")}
                disabled={currentPage === 0}
                icon={<ArrowHeadRight />}
              />
            </ButtonSpacer>
            <IconButton
              type="white-with-border"
              size="md"
              onClick={() => changeSlide(currentSlide + slidesToScroll, "next")}
              disabled={currentPage === lastPage}
              icon={<ArrowHeadRight />}
            />
          </>
        );
      case "minimal":
        return (
          <>
            <IconButton
              type="no-background-primary"
              size="sm"
              onClick={() => changeSlide(currentSlide - slidesToScroll)}
              disabled={currentPage === 0}
              icon={<ArrowHeadLeft />}
              {...(dataElementId ? { "data-element-id": dataElementId } : {})}
            />
            <DotsSection
              className={clsx(navigationType === "minimal" && "dots-section--min-margin")}
            >
              <CarouselDotBar
                lastPage={lastPage}
                currentPage={currentPage}
                changeSlide={changeSlide}
                navigationType={navigationType}
              />
            </DotsSection>
            <IconButton
              type="no-background-primary"
              size="sm"
              onClick={() => changeSlide(currentSlide + slidesToScroll)}
              disabled={currentPage === lastPage}
              icon={<ArrowHeadRight />}
              {...(dataElementId ? { "data-element-id": dataElementId } : {})}
            />
          </>
        );
      case "none":
        return null;
      case "tutorialpills-popup":
        return (
          <>
            <CarouselDotBar
              lastPage={lastPage}
              currentPage={currentPage}
              changeSlide={changeSlide}
              navigationType={navigationType}
              backgroundDark={mode === "white"}
            />
          </>
        );
      default:
        null;
    }
  };

  return (
    <StyledNavigation className={NavigationClasses} fadingSlides={fadingSlides}>
      {renderNavigationType()}
    </StyledNavigation>
  );
};

const StyledNavigation = styled.div<{ fadingSlides?: boolean }>`
  /* height: 3.75rem; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;

  &.tutorialpills-popup {
    width: 100%;
    justify-content: center;
  }

  &.homepage-navigation {
    position: relative;
  }

  &.central-navigation {
    width: 100%;
    justify-content: center;
  }

  &.multidoor-add-size {
    width: 100%;
    justify-content: flex-end;
  }

  &.lp-banner {
    position: absolute;
    top: calc(50% - 1.5rem);
    width: 100%;
    padding: 0 2.5rem;
    justify-content: space-between;
  }

  &.accordion-navigation {
    z-index: 1;
  }
  &.up-right-navigation {
    position: absolute;
    top: ${({ fadingSlides }) => `${fadingSlides ? "-30%" : "-2.5%"}`};
    right: -6%;
    transform: translate(-90%, 0);
  }

  .highlight-button {
    bottom: -20rem;
    right: 0;
    position: absolute;
    z-index: 1;

    &.button-prev {
      left: 0;

      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

const ElevatedButton = styled.div`
  z-index: 1;
`;

const DotsSection = styled.div`
  margin: 0 2.5rem;

  &.dots-section--min-margin {
    margin: 0 1rem;
  }
`;

const ButtonSpacer = styled.div`
  margin-right: 0.8125rem;

  svg {
    transform: rotate(180deg);
  }

  &.central-navigation {
    margin-right: unset;
  }
`;

const NextButtonLateral = styled.div`
  top: 3.75rem;
  right: -2.5rem;
  position: absolute;
  padding-right: 2.5rem;
  z-index: 1;

  button {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.13);
  }
`;

const PrevButtonLateral = styled.div`
  top: 3.75rem;
  left: -0.625rem;
  position: absolute;
  z-index: 1;

  button {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.13);
  }

  svg {
    margin-right: 0.125rem;
  }
`;

const LpBannerButton = styled.div``;

export default CarouselNavBar;
