import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";

import useGetAllThirdLevelMenu from "../../../../hooks/useGetAllThirdLevelMenu";
import { MostUsedChipLocal } from "../../../../interfaces/mostUsedInterfaces";
import { sendAnalyticsData } from "../../../../store/analytics/analyticsService";
import { selectGetMenuStatus, selectMenu } from "../../../../store/store/storeSlice";
import { getMostUsedContent } from "../../../../store/user/userSagas";
import { selectMostUsedContent, selectMostUsedLoading } from "../../../../store/user/userSlice";
import WrapperLayout from "../../../layouts/WrapperLayout";
import useNavbarSectionLinkHandler from "../../../layouts/header/navbar/useNavbarSectionLinkHandler";
import Chip from "../../../styled-UI/Chip";
import CustomText from "../../../styled-UI/CustomText";
import LoaderHomepageMostUsed from "../../../styled-UI/loader/most-used/LoaderHomepageMostUsed";
import CMSMostUsed from "./CMSMostUsed";
import CustomizeHomeModal from "./CustomizeHomeModal";
import useSelectedMostUsed, { ChipsHandler } from "./useSelectedMostUsed";
import { normalizeChipLabel } from "../../../../utils/mostUsedUtils";
import { useDragScroll } from "../../../../hooks/useDragScroll";

const PersonalizedChips = ({ chipsHandler }: { chipsHandler: ChipsHandler }): JSX.Element => {
  const history = useHistory();
  const { translateLabel } = useTranslation();
  const { handleNavbarSectionLinkClick } = useNavbarSectionLinkHandler();

  const handleRedirect = (chip: MostUsedChipLocal) => {
    if (chip.type === "Services&MyAccount")
      handleNavbarSectionLinkClick({
        identifier: chip.name,
        title: chip.label,
        url: chip.url,
        routes: [],
      });
    else history.push(chip.url);

    sendAnalyticsData({
      id: "Click",
      Tracking_Type: "link",
      data_element_id: "MostUsed_Tag",
    });
  };

  if (chipsHandler.selectedChips.length === 0) return <></>;
  return (
    <>
      {chipsHandler.selectedChips.map((chip) => (
        <Chip
          key={chip.name + chip.label}
          text={
            chip.type === "Brands" || chip.type === "Services&MyAccount" || chip.type === "Essilor"
              ? chip.label
              : translateLabel(normalizeChipLabel(chip.label))
          }
          onClick={() => handleRedirect(chip)}
          hideIcon={true}
          noMaxWidth
          showTitle
          style="bg-gray"
          borderRadiusPx={8}
          widthPx={172}
          heightPx={44}
          paddingLeftPx={16}
          paddingRightPx={16}
          gapPx={16}
          justifyContent="center"
          fontSize={12}
          lineHeightPx={18}
          letterSpacingPx={0.2}
          fontWeight={700}
          inheritWidth
        />
      ))}
    </>
  );
};

const MostUsed = (): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const getThirdLevelMenu = useGetAllThirdLevelMenu();
  const menuData = useSelector(selectMenu);

  const [customizeOpen, setCustomizeOpen] = useState<boolean>(false);

  const { ref, isLeftGradientVisible, isRightGradientVisible } = useDragScroll();
  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////// PERSONAL MOST USED //////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const chipsHandler = useSelectedMostUsed();

  const mostUsedData = useSelector(selectMostUsedContent);
  const mostUsedLoading = useSelector(selectMostUsedLoading);
  const getMenuStatus = useSelector(selectGetMenuStatus);

  const isLoading =
    mostUsedLoading.get === "LOADING" ||
    mostUsedLoading.update === "LOADING" ||
    getMenuStatus === "LOADING";

  useEffect(() => {
    mostUsedData.bookmarks.length == 0 && dispatch(getMostUsedContent());
  }, []);

  //fetch api to get third level menu if not yet available
  useEffect(() => {
    getThirdLevelMenu(
      customizeOpen,
      menuData
        .filter(({ identifier }) => identifier === "PRODUCTS")
        .flatMap(({ catalogGroupView }) => catalogGroupView || [])
    );
  }, [customizeOpen, menuData]);

  const renderPersonalMostUsed = () => {
    if (chipsHandler.selectedChips.length === 0 || isLoading) return <LoaderHomepageMostUsed />;
    // return (
    //   <CustomText fontSizePx={16} as="span" color="white" lineHeightPx={16} wordBreak="normal">
    //     {translateLabel("MOST_USED_NO_CONFIGURATION")}
    //   </CustomText>
    // );

    return <PersonalizedChips chipsHandler={chipsHandler} />;
  };

  //////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////// RENDER ////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const showPersonalMostUsed = !mostUsedData.unused;

  return (
    <LayoutBackground>
      <WrapperLayout width100Sm>
        <Section data-element-id="MostUsed">
          <TextContainer>
            <CustomText
              fontSizePx={24}
              as="span"
              color="white"
              lineHeightPx={24}
              wordBreak="normal"
              isEllipsis
              width={211}
            >
              {translateLabel("MOST_USED")}
            </CustomText>
          </TextContainer>
          <Chips
            ref={ref}
            isLeftGradientVisible={isLeftGradientVisible}
            isRightGradientVisible={isRightGradientVisible}
            data-element-id="MostUsed_Tag"
          >
            {showPersonalMostUsed
              ? renderPersonalMostUsed()
              : !isLoading && (
                  <CMSMostUsed
                    accountServiceChips={chipsHandler.availableChips}
                    preselectedChips={<PersonalizedChips chipsHandler={chipsHandler} />}
                  />
                )}

            <Chip
              disabled={!mostUsedData.canEdit}
              letterCase="capitalize"
              key="test"
              text={translateLabel("MOST_USED_EDIT_SETTINGS_LABEL")}
              onClick={() => setCustomizeOpen(true)}
              hideIcon={true}
              showTitle
              style="bg-gray"
              borderRadiusPx={8}
              maxWidth="10.75rem"
              heightPx={44}
              paddingLeftPx={16}
              paddingRightPx={16}
              gapPx={16}
              justifyContent="center"
              fontSize={12}
              lineHeightPx={18}
              letterSpacingPx={0.2}
              fontWeight={700}
              typeIcon="plus"
              showFileIcon
              iconWithoutMargin
              customSpacedContent={8}
              spacedContent
              inheritWidth={true}
            />
          </Chips>
        </Section>
        {customizeOpen && (
          <CustomizeHomeModal isOpen={customizeOpen} close={() => setCustomizeOpen(false)} />
        )}
      </WrapperLayout>
    </LayoutBackground>
  );
};

const Section = styled.div`
  // @media screen and (max-width: 1366px) {
  //   background: linear-gradient(270deg, #011f5d 0%, #031846 50.11%, #031846 100%);
  // }
  position: relative;
  display: flex;
  justify-content: center;
  height: 4.75rem;
  gap: 3rem;
  align-items: center;
`;

interface ChipsProps {
  isLeftGradientVisible: boolean;
  isRightGradientVisible: boolean;
}
const Chips = styled.div<ChipsProps>`
  display: flex;
  justify-content: flex-start;
  height: inherit;
  align-items: center;
  margin-right: 1.5rem;
  gap: 0.5rem;
  height: inherit;
  flex: 1;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  & {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer, legacy Edge */
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2.188rem; /* Width of the gradient */
    pointer-events: none; /* Ensure gradients don't block interaction */
  }

  /* Left gradient */
  &::before {
    background: ${({ isLeftGradientVisible }) =>
      isLeftGradientVisible
        ? "linear-gradient(90deg, #091E56 0%, rgba(255, 255, 255, 0) 100%)"
        : "none"};
  }

  /* Right gradient */
  &::after {
    right: 1.5rem;
    background: ${({ isRightGradientVisible }) =>
      isRightGradientVisible
        ? "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #091E56 100%)"
        : "none"};
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  height: inherit;
  max-width: 13.188rem;
`;

const LayoutBackground = styled.div`
  background: linear-gradient(270deg, #011f5d 0%, #011b57 50.11%, #031434 100%);
`;

export default MostUsed;

export const MemoizedMostUsed = React.memo(MostUsed);
