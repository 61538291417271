import React, { ReactNode, useEffect, useState } from "react";
import clsx from "clsx";
import Slider, { LazyLoadTypes } from "react-slick";
import styled from "styled-components/macro";
import CarouselNavBar from "./CarouselNavBar";
import CarouselDotBar from "./CarouselDotBar";
import CustomText from "./CustomText";
import LinkButton from "./LinkButton";
import Button from "./Button";
import useTranslation from "../../hooks/useTranslation";

export type NavigationType =
  | "default"
  | "lateral"
  | "central"
  | "highlights"
  | "none"
  | "accordion"
  | "popup"
  | "multidoor-add-size"
  | "one-tile"
  | "lp-banner"
  | "minimal"
  | "vertical"
  | "minimal-arrow"
  | "tutorialpills-popup";

interface Props {
  children: any;
  title?: string;
  lazyLoad?: LazyLoadTypes;
  slidesToShow?: number;
  slidesToScroll?: number;
  fadingSlides?: boolean;
  focusOnSelect?: boolean;
  isInitialSlideIndexCurrentSlide?: boolean;
  navigationType: NavigationType;
  "data-element-id"?: string | null;
  "data-description"?: string;
  noPaddingBottom?: boolean;
  paddingTopBottom?: boolean;
  indexCurrentSlide?: number | null;
  callbackOnChangeSlide?: (page: number) => void;
  callbackOnClosePage?: () => void;
  resetPaginationOnSlidesToShowChange?: boolean;
  fullHeightSlides?: boolean;
  customGradient?: string;
  variableWidth?: boolean;
  goToSlide?: number;
}

const Carousel = ({
  children,
  title,
  lazyLoad = "ondemand",
  slidesToShow = 1,
  slidesToScroll = 1,
  fadingSlides = false,
  focusOnSelect = false,
  isInitialSlideIndexCurrentSlide = false,
  navigationType,
  "data-element-id": dataElementId,
  "data-description": dataDescription,
  noPaddingBottom = false,
  paddingTopBottom = false,
  indexCurrentSlide,
  callbackOnChangeSlide,
  callbackOnClosePage,
  resetPaginationOnSlidesToShowChange,
  fullHeightSlides = false,
  customGradient = "white 18%",
  variableWidth = false,
  goToSlide,
}: Props): JSX.Element => {
  const [sliderRef, setSliderRef] = useState<any>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [totalSlides, setTotalSlides] = useState<number>(children?.length ?? 0);
  const currentPage = Math.ceil(currentSlide / slidesToScroll);
  const lastPage = Math.ceil((totalSlides - slidesToShow) / slidesToScroll);
  const { translateLabel } = useTranslation();

  const ContainerClasses = clsx(navigationType === "lateral" && "lateral-navigation");

  const ContainerSliderClasses = clsx(
    fadingSlides && currentPage !== lastPage && "fading-carousel-right",
    navigationType === "lateral" && "lateral-navigation",
    noPaddingBottom && "no-padding-bottom",
    paddingTopBottom && "padding-top-bottom",
    fullHeightSlides && "full-height-slides",
    navigationType === "tutorialpills-popup" && "tutorialpills-popup"
  );

  const NavigationClasses = clsx(
    navigationType === "lateral" && "lateral-navigation",
    navigationType === "tutorialpills-popup" && "tutorialpills-popup"
  );
  const defaultSettings = {
    dots: false,
    focusOnSelect: focusOnSelect,
    arrows: false,
    draggable: false, //to set it to true --> prevent click on drag https://github.com/akiran/react-slick/issues/848
    speed: 500,
    infinite: false,
    lazyLoad: lazyLoad,
    onSwipe: () => {
      console.log("swipe");
    },
    slidesToShow: slidesToShow < totalSlides ? slidesToShow : totalSlides,
    slidesToScroll: slidesToScroll,
    initialSlide: isInitialSlideIndexCurrentSlide ? indexCurrentSlide ?? 0 : 0,
    adaptiveHeight: true,
    beforeChange: (_: any, next: any) => setCurrentSlide(next),
  };

  const changeSlide = (val: number) => {
    sliderRef?.slickGoTo(val);
    callbackOnChangeSlide && callbackOnChangeSlide(val);
  };

  useEffect(() => {
    if (goToSlide && sliderRef) changeSlide(goToSlide);
  }, [goToSlide]);

  useEffect(() => {
    if (sliderRef?.props?.children && Array.isArray(sliderRef.props.children))
      setTotalSlides(sliderRef.props.children.length);
  }, [sliderRef]);

  useEffect(() => {
    if (sliderRef && indexCurrentSlide) sliderRef.slickGoTo(indexCurrentSlide);
  }, [sliderRef]);

  useEffect(() => {
    if (resetPaginationOnSlidesToShowChange && currentSlide !== 0) {
      setCurrentSlide(0);
      sliderRef.slickGoTo(0);
    }
  }, [slidesToShow]);

  const renderCustomButtons = () => {
    return (
      <TutorialPillsButton>
        {currentPage > 0 && (
          <Button
            type="secondary"
            buttonType="submit"
            {...(dataElementId ? { "data-element-id": `${dataElementId}_Back` } : {})}
            onClick={() => changeSlide(currentPage - 1)}
          >
            {translateLabel("TUTORIAL_PILLS_POPUP_BACK")}
          </Button>
        )}
        <Button
          type="primary"
          buttonType="submit"
          {...(dataElementId
            ? {
                "data-element-id":
                  `${dataElementId}_` + (lastPage === currentPage ? "Close" : "Next"),
              }
            : {})}
          onClick={() => {
            if (lastPage === currentPage) callbackOnClosePage?.();
            else changeSlide(currentPage + 1);
          }}
        >
          {lastPage === currentPage
            ? translateLabel("TUTORIAL_PILLS_POPUP_CLOSE")
            : translateLabel("TUTORIAL_PILLS_POPUP_NEXT")}
        </Button>
      </TutorialPillsButton>
    );
  };

  const renderSkipButton = () => {
    return (
      <SkipButton>
        {lastPage !== currentPage && (
          <LinkButton
            {...(dataElementId ? { "data-element-id": `${dataElementId}_Skip` } : {})}
            bold
            underline={false}
            onClick={() => changeSlide(lastPage)}
          >
            {translateLabel("TUTORIAL_PILLS_POPUP_SKIP")}
          </LinkButton>
        )}
      </SkipButton>
    );
  };

  return (
    <Container
      className={ContainerClasses}
      {...(dataElementId ? { "data-element-id": dataElementId } : {})}
    >
      {title && (
        <TitleWrapper>
          <CustomText fontSizePx={28} as="span" color="primary" lineHeightPx={28}>
            {title}
          </CustomText>
        </TitleWrapper>
      )}
      <SliderContainer customGradient={customGradient} className={ContainerSliderClasses}>
        <Slider variableWidth={variableWidth} ref={(val) => setSliderRef(val)} {...defaultSettings}>
          {children}
        </Slider>
      </SliderContainer>
      <NavigationSection className={NavigationClasses}>
        {navigationType === "tutorialpills-popup" && renderSkipButton()}
        {totalSlides > 1 && totalSlides > slidesToShow ? (
          <CarouselNavBar
            currentSlide={currentSlide}
            slidesToScroll={slidesToScroll}
            changeSlide={changeSlide}
            currentPage={currentPage}
            lastPage={lastPage}
            navigationType={navigationType}
            {...(dataElementId ? { "data-element-id": dataElementId } : {})}
            {...(dataDescription ? { "data-description": dataDescription } : {})}
          />
        ) : (
          <div></div>
        )}
        {navigationType === "tutorialpills-popup" && renderCustomButtons()}
        {navigationType === "lateral" ||
          (navigationType === "lp-banner" && (
            <>
              {totalSlides > 1 && totalSlides > slidesToShow ? (
                <CarouselDotBar
                  lastPage={lastPage}
                  currentPage={currentPage}
                  changeSlide={changeSlide}
                  navigationType={navigationType}
                />
              ) : (
                <div></div>
              )}
            </>
          ))}
      </NavigationSection>
    </Container>
  );
};

const TitleWrapper = styled.div`
  align-items: center;
  width: 100%;
`;

const TutorialPillsButton = styled.div`
  align-items: center;
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
  justify-content: end;
`;

const Container = styled.div`
  position: relative;
  &.lateral-navigation {
    position: relative;
  }
`;

const SliderContainer = styled.div<{ customGradient?: string }>`
  padding-bottom: 3rem;

  &.tutorialpills-popup {
    padding-bottom: 0;
  }

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  &.padding-top-bottom {
    padding: 3rem 0 3rem 0;
  }

  &.fading-carousel-right {
    .slick-list::before {
      content: "";
      display: block;
      width: 17.5rem;
      height: 100%;
      position: absolute;
      right: 0px;
      /* background: linear-gradient(to left, white 18%, rgba(255, 255, 255, 0) 100%); */
      background: ${(props) =>
        `linear-gradient(to left, ${props.customGradient} , rgba(255, 255, 255, 0) 100%)`};
      z-index: 2;
      pointer-events: none;
    }
  }

  &.lateral-navigation {
    padding-bottom: unset;

    .slick-list {
      padding: 1px 0;
    }
  }

  &.full-height-slides {
    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      height: inherit !important;

      > div {
        height: 100%;
      }
    }
  }
`;

// const DotBarNavigation = styled.div`
//   position: absolute;
//   bottom: 7rem;
//   right: 50%;
// `;

const NavigationSection = styled.div`
  /* margin-right: 1.25rem; */
  display: flex;
  justify-content: flex-end;

  &.tutorialpills-popup {
    padding: 1.5rem 2rem;
    justify-content: space-between;
  }
`;

const SkipButton = styled.div`
  align-self: center;
  width: 100%;
`;
export default Carousel;
