import { AdvTile } from "../interfaces/cmsInterfaces";
import {
  Attribute,
  ItemPrice,
  Product,
  Sku,
  ProductCtas,
  Variant,
  AttributeSlim,
  ProductCategory,
  ProductTags,
  ProductTagsUsage,
  AttributeValue,
} from "../interfaces/productInterface";
import {
  AvailabilityInfo,
  AvailabilityStatus,
  AvailabilityStatusByDoor,
} from "../store/catalogue/catalogueInterface";
import { formatDateNoLocale } from "./dateUtils";

export const CATENTRY = {
  PRODUCT: "ProductBean",
  VARIANT: "VariantBean",
  SKU: "ItemBean",
};

export const TYPE = {
  PRODUCT: "product",
  VARIANT: "variant",
  SKU: "sku",
};

/**
 * Returns the category type
 *
 * @param {string} catEntry
 * @return {string} category
 */
const getType = (catEntry: string): string => {
  if (catEntry === CATENTRY.PRODUCT) {
    return TYPE.PRODUCT;
  }
  if (catEntry === CATENTRY.VARIANT) {
    return TYPE.VARIANT;
  }
  if (catEntry === CATENTRY.SKU) {
    return TYPE.SKU;
  }
  return "";
};

const getNumberOfColors = (data: any) => {
  if (data.groupingProperties && data.groupingProperties.colorCount) {
    return data.groupingProperties.colorCount;
  }
  if (data.numberOfVariants) {
    return data.numberOfVariants;
  }
  return 0;
};

export const sanitizePartNumber = (partNumber: string): string => {
  const sanitizedPartNumber = partNumber.replace(/_/g, " ");
  return sanitizedPartNumber;
};

export const getAttribute = (attributes: Attribute[], attrId: string): Attribute | null => {
  if (attributes) {
    const filteredAttr = attributes.filter((attr: Attribute) => attr.identifier === attrId);
    if (filteredAttr.length > 0) return filteredAttr[0];
  }

  return null;
};

export const getAttributeValues = (
  attributes: Attribute[],
  attributeName: string
): AttributeSlim | undefined => {
  const attributeComplete = getAttribute(attributes, attributeName);
  if (attributeComplete && attributeName === "GENDER") {
    return {
      name: attributeComplete?.name,
      values: attributeComplete?.values?.map((x) => x.identifier).join(", "),
      valuesIdentifier: attributeComplete?.values?.map((_) => _.identifier).join(", "),
    };
  } else
    return {
      name: attributeComplete?.name,
      values: attributeComplete?.values?.map((_) => _.value).join(", "),
      valuesIdentifier: attributeComplete?.values?.map((_) => _.identifier).join(", "),
    };
};

export const getFirstAttributeValue = (
  attributes: Attribute[],
  attributeName: string
): AttributeValue | undefined => {
  const attributeComplete: Attribute | null = getAttribute(attributes, attributeName);
  if (attributeComplete?.values && attributeComplete?.values?.length > 0) {
    return attributeComplete?.values[0];
  }
};

export const getBoolAttribute = (attributes: Attribute[], attributeName: string): boolean => {
  const attributeComplete = getAttribute(attributes, attributeName);
  if (attributeComplete) {
    if (attributeName === "RTR")
      return attributeComplete?.values?.[0]?.value === "TRUE" ? true : false;
    return attributeComplete?.values?.[0]?.value ? true : false;
  }

  return false;
};

type TagsFiltersSchema = {
  [key in ProductCategory]: {
    id: string;
    value?: string;
    labelKey?: string;
    usage: ProductTagsUsage[];
  }[];
};

const TAGS_FILTERS: TagsFiltersSchema = {
  frame: [
    {
      id: "BESTSELLER",
      value: "TRUE",
      usage: ["active", "aftersales", "pdp-carousel"],
    },
    { id: "ADVERTISING", value: "TRUE", usage: ["active"] },
    { id: "CUSTOMIZABLE", value: "TRUE", usage: ["active"] },
    { id: "KEYLOOK", usage: ["active"] },
    {
      id: "NEW_COLOR",
      value: "TRUE",
      labelKey: "NEW_COLOR_LABEL",
      usage: ["active", "aftersales", "pdp-carousel"],
    },
    {
      id: "NEW_STYLE",
      value: "TRUE",
      labelKey: "NEW_STYLE_LABEL",
      usage: ["active", "aftersales", "pdp-carousel"],
    },
    { id: "POLARIZED", value: "TRUE", usage: ["active", "aftersales"] },
    { id: "EXCLUSIVE_CONTENT", value: "INITIATIVE", usage: ["active"] },
    { id: "STARS_ASSORTMENT", usage: ["stars"] },
  ],
  smartglasses: [
    {
      id: "BESTSELLER",
      value: "TRUE",
      usage: ["active", "aftersales", "pdp-carousel"],
    },
    { id: "ADVERTISING", value: "TRUE", usage: ["active"] },
    { id: "CUSTOMIZABLE", value: "TRUE", usage: ["active"] },
    {
      id: "NEW_COLOR",
      value: "TRUE",
      labelKey: "NEW_COLOR_LABEL",
      usage: ["active", "aftersales", "pdp-carousel"],
    },
    {
      id: "NEW_STYLE",
      value: "TRUE",
      labelKey: "NEW_STYLE_LABEL",
      usage: ["active", "aftersales", "pdp-carousel"],
    },
    { id: "POLARIZED", value: "TRUE", usage: ["active", "aftersales"] },
    { id: "EXCLUSIVE_CONTENT", value: "INITIATIVE", usage: ["active"] },
  ],
  afa: [
    { id: "BESTSELLER", value: "TRUE", usage: ["active", "pdp-carousel", "aftersales", "stars"] },
    { id: "ADVERTISING", value: "TRUE", usage: ["active"] },
    { id: "KEYLOOK", usage: ["active"] },
    {
      id: "NEW_COLOR",
      value: "TRUE",
      labelKey: "NEW_COLOR_LABEL",
      usage: ["active", "pdp-carousel"],
    },
    {
      id: "NEW_STYLE",
      value: "TRUE",
      labelKey: "NEW_STYLE_LABEL",
      usage: ["active", "pdp-carousel"],
    },
    { id: "POLARIZED", value: "TRUE", usage: ["active"] },
    { id: "EXCLUSIVE_CONTENT", value: "INITIATIVE", usage: ["active"] },
  ],
  helmet: [
    { id: "BESTSELLER", value: "TRUE", usage: ["active", "pdp-carousel"] },
    { id: "ADVERTISING", value: "TRUE", usage: ["active"] },
    { id: "KEYLOOK", usage: ["active"] },
    {
      id: "NEW_COLOR",
      value: "TRUE",
      labelKey: "NEW_COLOR_LABEL",
      usage: ["active", "pdp-carousel"],
    },
    {
      id: "NEW_STYLE",
      value: "TRUE",
      labelKey: "NEW_STYLE_LABEL",
      usage: ["active", "pdp-carousel"],
    },
    { id: "EXCLUSIVE_CONTENT", value: "INITIATIVE", usage: ["active"] },
  ],
  accessory: [
    { id: "BESTSELLER", value: "TRUE", usage: ["active", "pdp-carousel"] },
    { id: "ADVERTISING", value: "TRUE", usage: ["active"] },
    {
      id: "NEW_COLOR",
      value: "TRUE",
      labelKey: "NEW_COLOR_LABEL",
      usage: ["active", "pdp-carousel"],
    },
    {
      id: "NEW_STYLE",
      value: "TRUE",
      labelKey: "NEW_STYLE_LABEL",
      usage: ["active", "pdp-carousel"],
    },
    { id: "POLARIZED", value: "TRUE", usage: ["active"] },
    { id: "EXCLUSIVE_CONTENT", value: "INITIATIVE", usage: ["active"] },
  ],
  goggle: [
    { id: "BESTSELLER", value: "TRUE", usage: ["active", "pdp-carousel"] },
    { id: "ADVERTISING", value: "TRUE", usage: ["active"] },
    { id: "KEYLOOK", usage: ["active"] },
    {
      id: "NEW_COLOR",
      value: "TRUE",
      labelKey: "NEW_COLOR_LABEL",
      usage: ["active", "aftersales", "pdp-carousel"],
    },
    {
      id: "NEW_STYLE",
      value: "TRUE",
      labelKey: "NEW_STYLE_LABEL",
      usage: ["active", "aftersales", "pdp-carousel"],
    },
    { id: "POLARIZED", value: "TRUE", usage: ["active", "aftersales"] },
    { id: "EXCLUSIVE_CONTENT", value: "INITIATIVE", usage: ["active"] },
  ],
  audio: [],
};

/**
 * Get array of tags for product
 * @param {Attribute[]} attributes
 * @param {ProductCategory} productCategory
 * @return {*}  {string[]}
 */
export const getTags = (
  attributes: Attribute[] | undefined,
  productCategory: ProductCategory
): ProductTags[] => {
  const tags: ProductTags[] = [];

  TAGS_FILTERS[productCategory]?.forEach((tag) => {
    const attribute = attributes?.filter((attr) => attr.identifier === tag.id);

    if (attribute && attribute.length > 0 && attribute[0].values) {
      if (
        attribute[0].identifier === "KEYLOOK" &&
        attribute[0].name &&
        attribute[0].values?.length > 0
      ) {
        tags.push({
          value: attribute[0].name,
          usage: tag.usage,
          identifier: attribute[0]?.identifier,
        });
      } else {
        attribute[0].values?.forEach((value) => {
          if (tag.value) {
            if (value.identifier.toUpperCase() === tag.value?.toUpperCase()) {
              if (tag.labelKey) {
                tags.push({
                  value: tag.labelKey,
                  usage: tag.usage,
                  identifier: attribute[0]?.identifier,
                });
              } else {
                if (attribute[0].name)
                  tags.push({
                    value: attribute[0].name,
                    usage: tag.usage,
                    identifier: attribute[0]?.identifier,
                  });
              }
            }
          } else {
            if (value.value) {
              tags.push({
                value: value.value,
                usage: tag.usage,
                identifier: attribute[0]?.identifier,
              });
            }
          }
        });
      }
    }
  });
  return tags;
};

export const getSubtitle = (attributes: any): string | undefined => {
  const ACC_PRODUCT_CATEGORY = getAttributeValues(attributes, "ACC_PRODUCT_CATEGORY")?.values;
  const FRAME_COMPONENTS = getAttributeValues(attributes, "FRAME_COMPONENTS")?.values;
  const OTHER_ACCESSORIES = getAttributeValues(attributes, "OTHER_ACCESSORIES")?.values;

  return ACC_PRODUCT_CATEGORY
    ? ACC_PRODUCT_CATEGORY
    : FRAME_COMPONENTS
    ? FRAME_COMPONENTS
    : OTHER_ACCESSORIES;
};

/**
 * Map sku of api object to our Sku
 * @param {any} sku
 * @return {*}  {Sku}
 */
export const mapSkuObj = (
  sku: any,
  fallbackProductCategoryFilter?: AttributeSlim | undefined
): Sku => {
  return {
    uniqueID: sku?.uniqueID,
    partNumber: sku?.partNumber,
    variantPartNumber: sku?.["identifier.mpn.raw"],
    name: sku?.name,
    description: sku?.shortDescription,
    brand: sku?.manufacturer,
    catEntry: getType(sku?.catalogEntryTypeCode),
    img: sku?.fullImage,
    seo: sku?.seo,
    size: getAttribute(sku?.attributes, "DL_SIZE_CODE"),
    sizeString: getAttributeValues(sku?.attributes, "DL_SIZE_CODE")?.values ?? "",
    productCategory: sku?.productCategory,
    upc: sku?.upc,
    colorCode: getAttributeValues(sku?.attributes, "DL_COLOR_CODE"),
    frontColorDescription: getAttributeValues(sku?.attributes, "FRONT_COLOR_DESCRIPTION"),
    // lensColorDescription: getAttribute(sku?.attributes, "LENS_COLOR_DESCRIPTION")?.values?.[0].value,
    attributes: sku?.attributes ? sku?.attributes : [],
    productCategoryFilter:
      getAttributeValues(sku?.attributes, "PRODUCT_CATEGORY_FILTER") ??
      fallbackProductCategoryFilter,
    tags: getTags(sku?.attributes, sku?.productCategory),
    isPromo: getBoolAttribute(sku?.attributes, "PROMO"),
    isStars: getBoolAttribute(sku?.attributes, "STARS"),
    isStarsNew: getBoolAttribute(sku?.attributes, "STARS_NEW"),
    isRxable: getBoolAttribute(sku?.attributes, "RX_COMPLETE_JOB"),
    isSeeOnInstagram: getBoolAttribute(sku?.attributes, "INSTAGRAM"),
    isRTR: getBoolAttribute(sku?.attributes, "RTR"),
    lensProperties: getAttributeValues(sku?.attributes, "LENS_PROPERTIES"),
    lensColor: getAttributeValues(sku?.attributes, "LENS_COLOR_DESCRIPTION"),
    frameMaterial: getAttributeValues(sku?.attributes, "FRAME_MATERIAL"),
    fitting: getAttributeValues(sku?.attributes, "FIT"),
    seoVariant: sku?.["relationship.variant.seo"],
    modelCode: sku?.["identifier.pn.parent"],
    parentCatalogEntryID: sku?.parentCatalogEntryID,
    starsNew:
      (sku?.attributes &&
        getAttribute(sku?.attributes, "STARS_NEW")?.values?.map(
          (attribute: any) => attribute?.value
        )) ||
      [],
    powerRange: getAttributeValues(sku?.attributes, "POWER_RANGE"),
  };
};

/**
 * Map sku array of api object to our Sku array
 * @param {any[]} skuArray
 * @return {*}  {Sku[]}
 */
export const mapSkusArray = (
  skuArray: any[],
  fallbackProductCategoryFilter?: AttributeSlim | undefined
): Sku[] => {
  return skuArray?.map(
    (sku: any): Sku => {
      return mapSkuObj(sku, fallbackProductCategoryFilter);
    }
  );
};

/**
 * Map variant of api object to our Variant
 * @param {*} variant
 * @return {*}  {Variant}
 */
export const mapVariantObj = (
  variant: any,
  fallbackProductCategoryFilter?: AttributeSlim | undefined
): Variant => {
  let subtitle: string | undefined = "";

  if (variant?.productCategory === "accessory") {
    subtitle = getSubtitle(variant?.attributes);
  }

  const productCategoryFilter =
    getAttributeValues(variant?.attributes, "PRODUCT_CATEGORY_FILTER") ??
    fallbackProductCategoryFilter;

  return {
    uniqueID: variant?.uniqueID,
    name: variant?.name,
    variantCode: variant?.partNumber,
    variantCodeNoUnderscore: variant?.partNumber?.replace("_", " "),
    description: variant?.shortDescription,
    catEntry: getType(variant?.catalogEntryTypeCode),
    img: variant?.fullImage,
    seo: variant?.seo,
    sizes:
      getAttribute(variant?.attributes, "DL_SIZE_CODE") ??
      getAttribute(variant?.attributes, "SIZE"), //TODO: delete fallback
    colorCode: getAttributeValues(variant?.attributes, "DL_COLOR_CODE"),
    frontColorDescription: getAttributeValues(variant?.attributes, "FRONT_COLOR_DESCRIPTION"),
    numberOfSku: variant?.numberOfSKUs,
    skus: variant?.sKUs ? mapSkusArray(variant.sKUs, productCategoryFilter) : [],
    productCategory: variant?.productCategory,
    parentCatalogEntryID: variant?.parentCatalogEntryID,
    attributes: variant?.attributes ? variant?.attributes : [],
    lensProperties: getAttributeValues(variant?.attributes, "LENS_PROPERTIES"),
    lensColor: getAttributeValues(variant?.attributes, "LENS_COLOR_DESCRIPTION"),
    frameMaterial: getAttributeValues(variant?.attributes, "FRAME_MATERIAL"),
    spareparts: variant?.spareparts !== undefined ? variant.spareparts : "false",
    warranty: variant?.warranty != undefined ? variant.warranty : "false",
    brand: variant?.manufacturer,
    subtitle,
    isPromo: getBoolAttribute(variant?.attributes, "PROMO"),
    isStars: getBoolAttribute(variant?.attributes, "STARS"),
    isStarsNew: getBoolAttribute(variant?.attributes, "STARS_NEW"),
    isRxable: getBoolAttribute(variant?.attributes, "RX_COMPLETE_JOB"),
    isSeeOnInstagram: getBoolAttribute(variant?.attributes, "INSTAGRAM"),
    isRTR: getBoolAttribute(variant?.attributes, "RTR"),
    tags: getTags(variant?.attributes, variant?.productCategory),
    productCategoryFilter: productCategoryFilter,
    longDescription: variant?.longDescription,
    auxDescription1: variant?.auxDescription1?.replace(/●/g, `• `),
    auxDescription2: variant?.auxDescription2,
    starsNew:
      (variant?.attributes &&
        getAttribute(variant?.attributes, "STARS_NEW")?.values?.map(
          (attribute: any) => attribute?.value
        )) ||
      [],
    powerRange: getAttributeValues(variant?.attributes, "POWER_RANGE"),
  };
};

/**
 * Map variant array of api object to our Variant array
 * @param {any[]} variantArray
 * @return {*}  {Variant[]}
 */
export const mapVariantsArray = (
  variantArray: any[],
  fallbackProductCategoryFilter?: AttributeSlim | undefined
): Variant[] => {
  return variantArray.map((elem) => {
    return mapVariantObj(elem, fallbackProductCategoryFilter);
  });
};

/*attributes:   
  isPromo, 
  isStar, 
  isRx, 
  isSeeOnInsta
*/
/**
 * Map product of api object to our Product
 * @param {*} product
 * @return {*}  {Product}
 */
export const mapProductObj = (product: any): Product => {
  const ctas: ProductCtas[] = [];
  let subtitle: string | undefined = "";

  if (
    product?.productCategory === "frame" ||
    product?.productCategory === "goggle" ||
    product?.productCategory === "smartglasses" ||
    product?.productCategory === "audio"
  ) {
    ctas.push({
      label: "PDP_SIMILAR_PRODUCTS_TITLE",
      url: `/plp-similar${product?.seo?.href}`,
      key: "SIMILAR_PRODUCTS",
    });

    if (product?.spareparts == "true")
      ctas.push({
        label: "SPARE_PARTS",
        url: `/spare-parts${product?.groupingProperties?.groupVariantSeo}`,
        key: "SPARE_PARTS",
      });
  }

  if (product?.productCategory === "accessory") {
    subtitle = getSubtitle(product?.attributes);
  }

  const productCategoryFilter = getAttributeValues(product?.attributes, "PRODUCT_CATEGORY_FILTER");

  return {
    uniqueID: product?.uniqueID,
    brand: product?.manufacturer,
    productCode: product?.partNumber,
    name: product?.name,
    counterColors: getNumberOfColors(product),
    counterSizes: product?.groupingProperties?.sizeCount ?? 0,
    img: product?.fullImage,
    tags: getTags(product?.attributes, product?.productCategory),
    seo: product?.seo,
    catEntry: getType(product?.catalogEntryTypeCode),
    productCategory: product?.productCategory,
    ctas,
    variants: product?.variants ? mapVariantsArray(product.variants, productCategoryFilter) : [],
    attributes: product?.attributes ?? [],
    spareparts: product?.spareparts !== undefined ? product.spareparts : false,
    warranty: product?.warranty != undefined ? product.warranty : false,
    subtitle,
    isPromo: getBoolAttribute(product?.attributes, "PROMO"),
    isStars: getBoolAttribute(product?.attributes, "STARS"),
    isStarsNew: getBoolAttribute(product?.attributes, "STARS_NEW"),
    isRxable: getBoolAttribute(product?.attributes, "RX_COMPLETE_JOB"),
    isRTR: getBoolAttribute(product?.attributes, "RTR"),
    isSeeOnInstagram: getBoolAttribute(product?.attributes, "INSTAGRAM"),
    productCategoryFilter: productCategoryFilter,
    groupVariantSeo: product?.groupingProperties?.groupVariantSeo,
    numberOfVariants: product?.numberOfVariants,
    numberOfStarsVariants: product?.numberOfStarsVariants,
    skuUniqueID: product?.groupingProperties?.groupHero,
    starsNew:
      (product?.attributes &&
        getAttribute(product?.attributes, "STARS_NEW")?.values?.map(
          (attribute: any) => attribute?.value
        )) ||
      [],
    powerRange: getAttributeValues(product?.attributes, "POWER_RANGE"),
  };
};

/**
 *  Map product of api object to our Adv
 * @param  {any} adv
 * @returns adv
 */
export const mapAdvObj = (adv: any): AdvTile => {
  return adv;
};

export const mapPriceObj = (price: any): ItemPrice => {
  return {
    opt: {
      unitPrice: price?.OPT?.[0]?.price?.value,
      currency: price?.OPT?.[0]?.price?.currency,
    },
    pub: {
      unitPrice: price?.PUB?.[0]?.price?.value,
      currency: price?.PUB?.[0]?.price?.currency,
    },
  };
};

/**
 * Map products and cms adv tile  array of api object to our Product array
 * @param {any[]} productArray
 * @return {*}  {(Product | AdvTile)[]}
 */
export const mapProductsAndAdvArray = (genericArray: any[]): (Product | AdvTile)[] => {
  return genericArray.map((elem) => {
    if (elem.catalogEntryTypeCode) {
      return mapProductObj(elem);
    } else {
      return mapAdvObj(elem);
    }
  });
};

/**
 * Map products array of api object to our Product array
 * @param {any[]} productArray
 * @return {*}  {Product[]}
 */
export const mapProductsArray = (genericArray: any[]): Product[] => {
  return genericArray?.map((elem) => {
    return mapProductObj(elem);
  });
};

/**
 * Custom type guard, check if object implements Product interface
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @export
 * @param {(Product | any)} object
 * @return {*}  {object is Product}
 */
export function instanceOfProduct(object: Product | any): object is Product {
  return object.catEntry === TYPE.PRODUCT;
}

/**
 * Custom type guard, check if object implements Variant interface
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @export
 * @param {(Product | any)} object
 * @return {*}  {object is Variant}
 */
export function instanceOfVariant(object: Product | any): object is Variant {
  return object.catEntry === TYPE.VARIANT;
}

/**
 * Custom type guard, check if object implements Variant interface
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @export
 * @param {(Product | any)} object
 * @return {*}  {object is Sku}
 */
export function instanceOfSku(object: Product | any): object is Sku {
  return object.catEntry === TYPE.SKU;
}

/**
 * Take variant array and reorder the array by putting the variant with the id passed to the functions
 * at first place
 *
 * @export
 * @param {Variant[]} variants
 * @param {string} currentVariantId
 * @return {*}  {Variant[]}
 */
export function putCurrentVariantAsFirst(variants: Variant[], currentVariantId: string): Variant[] {
  const currentVariant = variants.find((_) => _.uniqueID === currentVariantId);
  if (currentVariant)
    return [currentVariant, ...variants.filter((_) => !(_.uniqueID === currentVariantId))];
  else return variants;
}

/**
 * Map availability status of an array of doors (containing an array of products)
 *
 * @param {any[]} inventoryAvailabilityArray
 * @return {*}  {AvailabilityStatus[]}
 */
export const mapAvailability = (inventoryAvailabilityArray: any[]): AvailabilityStatusByDoor[] => {
  return inventoryAvailabilityArray.map(
    (doorObj): AvailabilityStatusByDoor => {
      const availabilityInfo: AvailabilityInfo[] = [];

      doorObj.inventoryAvailability.forEach((_: any) => {
        const newInfo: AvailabilityInfo = {
          uniqueId: _.productId,
          availableQuantity: _.availableQuantity,
          availabilityDateTime:
            _.availabilityDateTime ?? formatDateNoLocale(new Date(), "yyyy-MM-dd"), //usato per dividere in gruppo per afa OBBLIGATORIA tranne per AVAILABLE
          x_startShipDate: _.x_startShipDate, //datepicker OBBLIGATORIA
          x_state: _.x_state,
        };
        availabilityInfo.push(newInfo);
      });

      return {
        doorId: doorObj.door,
        availabilityStatus: doorObj.inventoryAvailability.map(
          (_: any): AvailabilityStatus => {
            return { [_.productId]: _.x_state ?? "" };
          }
        ),
        availabilityInfo: availabilityInfo,
      };
    }
  );
};

export const isEyeglassesVariant = (glass: Variant | Sku): boolean => {
  const checkProductCategoryFilter = glass.productCategoryFilter?.valuesIdentifier?.includes(
    "Eyeglasses"
  )
    ? true
    : false;
  return glass.productCategory === "frame" && checkProductCategoryFilter;
};

export const isSunglassesVariant = (glass: Variant | Sku): boolean => {
  const checkProductCategoryFilter = !!glass.productCategoryFilter?.valuesIdentifier?.includes(
    "Sunglasses"
  );
  return glass.productCategory === "frame" && checkProductCategoryFilter;
};

export const isReaderGlassesVariant = (glass: Variant | Sku): boolean => {
  const checkProductCategoryFilter = glass.productCategoryFilter?.valuesIdentifier?.includes(
    "Reader"
  )
    ? true
    : false;
  return glass.productCategory === "frame" && checkProductCategoryFilter;
};

/**
 * Get an array of skus' uniqueIds from an array of variants
 *
 * @param {Variant[]} variants
 * @return {*}  {string[]}
 */
export const getSkuIdsFromVariants = (variants: Variant[]): string[] => {
  const skuIds: string[] = [];

  variants.forEach((variant) => {
    const variantSkuIds = variant?.skus
      ? variant.skus?.map((_) => {
          return _.uniqueID;
        })
      : [];
    skuIds.push(...variantSkuIds);
  });

  return skuIds;
};

export const CATEGORIES_FOR_COMPATIBLE_WITH = ["accessory", "goggle", "frame", "smartglasses"];
export const CATEGORIES_FOR_SIMILAR_PRODUCTS = [
  "goggle",
  "frame",
  "smartglasses",
  "afa",
  "helmet",
  "audio",
];
export const CATEGORIES_FOR_STANDARD_MOODBOARD: (undefined | string)[] = [
  "frame",
  "smartglasses",
  "audio",
];

//Create unique translate key from parameters
export const createDescriptionTranslateKey = (
  facetIdentifier: string,
  valueIdentifier: string
): string => {
  return facetIdentifier && valueIdentifier
    ? `FACET_DESCRIPTION_${facetIdentifier}_${valueIdentifier.toUpperCase().split(" ").join("_")}`
    : "";
};
