import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import useGetThirdLevelMenu from "../../../../hooks/useGetThirdLevelMenu";
import useMostUsedLensesCMS from "../../../../hooks/useMostUsedLensesCMS";
import useTranslation from "../../../../hooks/useTranslation";
import { MostUsedItem } from "../../../../interfaces/cmsInterfaces";
import { ItemsMap, Page } from "../../../../interfaces/gridInterfaces";
import { SecondLevelMenu } from "../../../../interfaces/menuInterfaces";
import { MostUsedChipLocal } from "../../../../interfaces/mostUsedInterfaces";
import { getPagePath } from "../../../../routing/routesUtils";
import { sendAnalyticsData } from "../../../../store/analytics/analyticsService";
import {
  selectHomePageLayout,
  selectItemsMap,
  selectMenu,
} from "../../../../store/store/storeSlice";
import { useCanUrlBeRendered, useHandleCmsLink } from "../../../../utils/cmsUtils";
import { getMostUsedBrandsFromMenu, matchCMSChip } from "../../../../utils/mostUsedUtils";
import Chip from "../../../styled-UI/Chip";
import CMSMostUsedAccountServiceChip from "./Cms/CMSMostUsedAccountServiceChip";
import CMSMostUsedChip from "./Cms/CMSMostUsedChip";

interface Props {
  preselectedChips: JSX.Element;
  accountServiceChips:
    | {
        mostUsedAccount: MostUsedChipLocal[];
        mostUsedService: MostUsedChipLocal[];
      }
    | undefined;
}

const CMSMostUsed = ({ preselectedChips, accountServiceChips }: Props): JSX.Element => {
  const { handleCmsLink } = useHandleCmsLink();
  const { canUrlBeRendered } = useCanUrlBeRendered();
  //add static items
  const mostUsedLenses = useMostUsedLensesCMS();
  const itemsMap = useSelector(selectItemsMap);
  const layout = useSelector(selectHomePageLayout);
  const { translateLabel } = useTranslation();
  const menuData = useSelector(selectMenu);
  const [secondLevelMenus, setSecondLevelMenus] = useState<SecondLevelMenu[]>([]);
  const [availableBrandsChips, setAvailableBrandsChips] = useState<MostUsedChipLocal[]>([]);
  const [myAccountServiceChips, setMyAccountServiceChips] = useState<MostUsedChipLocal[]>([]);
  const getThirdLevelMenu = useGetThirdLevelMenu();

  useEffect(() => {
    if (menuData) {
      const secondLevMenus = menuData?.find((_) => _.identifier === "PRODUCTS")?.catalogGroupView;
      secondLevMenus && setSecondLevelMenus(secondLevMenus);
      const brands = getMostUsedBrandsFromMenu(menuData, translateLabel);
      setAvailableBrandsChips([...brands.brands, ...brands.brandsEssilor]);
    }
  }, [menuData]);

  useEffect(() => {
    if (accountServiceChips) {
      setMyAccountServiceChips([
        ...accountServiceChips.mostUsedAccount,
        ...accountServiceChips.mostUsedService,
      ]);
    }
  }, [accountServiceChips]);

  const mostUsedContent = useMemo(() => getMostUsedCMS(layout, itemsMap), [layout, itemsMap]);

  const canLinkBeRendered = (linkString?: string) => {
    if (!linkString) return true; // if no link is provided, render (to keep previous behavior)
    return canUrlBeRendered(getPagePath(linkString));
  };

  const handleRedirect = (link?: string, externalLink?: boolean, openInNewTab?: boolean): void => {
    handleCmsLink(link, externalLink, openInNewTab);
    sendAnalyticsData({
      id: "Click",
      Tracking_Type: "link",
      data_element_id: "MostUsed_Tag",
    });
  };

  return (
    <>
      {preselectedChips}
      {mostUsedContent?.map((mostUsed: MostUsedItem) => {
        const menu = secondLevelMenus?.find(
          (item) => item.identifier.toUpperCase() === mostUsed?.catalogueIdentifier
        );
        getThirdLevelMenu(!!menu, menu);
        if (canLinkBeRendered(mostUsed.linkString))
          return menu ? (
            <CMSMostUsedChip
              myAccountServiceChips={myAccountServiceChips}
              mostUsed={mostUsed}
              secondLevelMenu={menu}
              availableBrandsChips={availableBrandsChips}
            />
          ) : (
            <CMSMostUsedAccountServiceChip
              myAccountServiceChips={myAccountServiceChips}
              mostUsed={mostUsed}
              availableBrandsChips={availableBrandsChips}
            />
          );
      })}
      {mostUsedLenses?.map((mostUsed: MostUsedItem, index: number) => {
        <Chip
          key={index}
          letterCase="capitalize"
          customColor={mostUsed.titleColour}
          text={translateLabel(mostUsed.title)}
          hideIcon={true}
          onClick={() => {
            handleRedirect(mostUsed.linkString, mostUsed.externalLink, mostUsed.openInNewTab);
          }}
          style="bg-gray"
          borderRadiusPx={8}
          widthPx={172}
          heightPx={44}
          paddingLeftPx={16}
          paddingRightPx={16}
          gapPx={16}
          justifyContent="center"
          fontSize={12}
          lineHeightPx={18}
          letterSpacingPx={0.2}
          fontWeight={700}
          inheritWidth={true}
        />;
      })}
    </>
  );
};

const getMostUsedCMS = (layout: Page, itemsMap: ItemsMap): MostUsedItem[] => {
  if (layout && layout.rows) {
    let contentPage: MostUsedItem[] = [];
    const mostUsedRow = layout.rows.filter((row) => row.name === "most-used");

    if (mostUsedRow && mostUsedRow[0]?.cols && mostUsedRow[0].cols[0]) {
      const id = mostUsedRow[0].cols[0].id;

      if (id && itemsMap[id]) {
        contentPage = itemsMap[id]?.result?.map((obj: MostUsedItem) => {
          return { ...obj, catalogueIdentifier: matchCMSChip(obj.linkString) };
        });
      }
    }
    return [...contentPage];
  }

  return [];
};
export default CMSMostUsed;
