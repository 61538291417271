import React, { useEffect, useState } from "react";
import useTranslation from "../../../../../hooks/useTranslation";
import { MostUsedItem } from "../../../../../interfaces/cmsInterfaces";
import { SecondLevelMenu } from "../../../../../interfaces/menuInterfaces";
import {
  MostUsedChipCatalogueLocal,
  MostUsedChipLocal,
} from "../../../../../interfaces/mostUsedInterfaces";
import { getPagePath } from "../../../../../routing/routesUtils";
import { sendAnalyticsData } from "../../../../../store/analytics/analyticsService";
import { useHandleCmsLink } from "../../../../../utils/cmsUtils";
import Chip from "../../../../styled-UI/Chip";
import useGenerateCatalogueMostUsed from "../useGenerateCatalogueMostUsed";

interface Props {
  mostUsed: MostUsedItem;
  secondLevelMenu: SecondLevelMenu;
  availableBrandsChips: MostUsedChipLocal[];
  myAccountServiceChips: MostUsedChipLocal[];
}

const CMSMostUsedChip = ({ mostUsed, secondLevelMenu }: Props): JSX.Element => {
  const { handleCmsLink } = useHandleCmsLink();
  const { translateLabel } = useTranslation();
  const { linkString, title, titleColour, externalLink, openInNewTab } = mostUsed;
  const [selectableChips, setSelectableChips] = useState<MostUsedChipCatalogueLocal[]>([]);
  const [showChip, setShowChip] = useState<boolean>(false);
  const catalogueChips = useGenerateCatalogueMostUsed(secondLevelMenu);

  useEffect(() => {
    catalogueChips?.length && setSelectableChips(catalogueChips);
  }, [catalogueChips]);

  useEffect(() => {
    selectableChips?.length &&
      setShowChip(
        selectableChips?.some((item) => {
          if (mostUsed.linkString) return item.url === getPagePath(mostUsed.linkString.trim());
          return false;
        })
      );
  }, [selectableChips]);

  const handleRedirect = (link?: string, externalLink?: boolean, openInNewTab?: boolean): void => {
    handleCmsLink(link, externalLink, openInNewTab);
    sendAnalyticsData({
      id: "Click",
      Tracking_Type: "link",
      data_element_id: "MostUsed_Tag",
    });
  };

  return (
    <>
      {showChip && (
        <Chip
          letterCase="capitalize"
          customColor={titleColour}
          text={translateLabel(title)}
          hideIcon={true}
          onClick={() => {
            handleRedirect(linkString, externalLink, openInNewTab);
          }}
          style="bg-gray"
          borderRadiusPx={8}
          widthPx={172}
          heightPx={44}
          paddingLeftPx={16}
          paddingRightPx={16}
          gapPx={16}
          justifyContent="center"
          fontSize={12}
          lineHeightPx={18}
          letterSpacingPx={0.2}
          fontWeight={700}
          inheritWidth={true}
        />
      )}
    </>
  );
};

export default CMSMostUsedChip;
