import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import useTranslation from "../../../hooks/useTranslation";

import {
  ItemPrice,
  Product,
  ProductTagsUsage,
  Variant,
} from "../../../interfaces/productInterface";
import { getPagePath } from "../../../routing/routesUtils";
import {
  selectLoadingPlp,
  selectSelectedTile,
  setSelectedTile,
} from "../../../store/catalogue/catalogueSlice";
import { selectDisplayPrices } from "../../../store/user/userSlice";
import { instanceOfProduct, instanceOfVariant } from "../../../utils/productUtils";
import CustomProgressiveImage from "../../styled-UI/CustomProgressiveImage";
import CustomText from "../../styled-UI/CustomText";
import SeeAllVariantLoader from "../../styled-UI/loader/loader-tile/SeeAllVariantLoader";
import PriceTile from "../PriceTile";
import SizeSelector from "../SizeSelector";
import TileHeader from "./TileHeader";
import TileLensInfo from "./TileLensInfo";
import TileTags from "./TileTags";
import TileFooter from "./tile-footer/TileFooter";

import { ReactComponent as MulticolorCircle } from "../../../assets/icons/multicolor-circle.svg";
import { getPDPTechIconsCms } from "../../../store/catalogue/catalogueSaga";
import {
  selectBrandGroupByBrandMap,
  selectTechIconContents,
} from "../../../store/store/storeSlice";
import { Door } from "../../../store/user/userInterfaces";
import { checkIsAFAOrHelmet } from "../../../utils/AFAutils";
import Button from "../../styled-UI/Button";
import AFAIconRender from "../AFAIconRender";

interface Props {
  data: Product | Variant;
  hideTag?: boolean;
  hideFooter?: boolean;
  hideSeeVariants?: boolean;
  hidePrices?: boolean;
  hideColorsVariant?: boolean;
  showSizeRadio?: boolean;
  "data-element-id"?: string;
  "data-description"?: string;
  "data-element-id-img"?: string;
  "data-description-img"?: string;
  productCode?: string;
  enableDataDescription?: boolean;
  isCarousel?: boolean;
  cssStyle?: "space-between" | "default";
  noLinkImg?: boolean;
  showBrandInVariant?: boolean;
  showModelInVariant?: boolean;
  callbackSizeSelector?: (uniqueID: string) => void;
  optionalCta?: (selected: Product | Variant) => void;
  isVariantCartTile?: boolean;
  tagTilesUsage?: ProductTagsUsage;
  showCodeInVariant?: boolean;
  currentDoor?: Door;
  isStandaloneVariantTile?: boolean;
  showAddToCartButton?: boolean;
  addToCart?: (data: Variant) => void;
  isMultidoor?: boolean;
  price?: ItemPrice;
  isLoading?: boolean;
  starsSkus?: string[];
  hasColorOnTop?: boolean;
  isKeylookTile?: boolean;
  isExtendedTile?: boolean;
  isKeylookOrSimilarTile?: boolean;
  hideFooterPlaceholder?: boolean;
  borderBottomLensContainer?: boolean;
}

const Tile = ({
  data,
  hideFooter = false,
  hideTag = false,
  hideSeeVariants = false,
  hidePrices = false,
  showSizeRadio = false,
  hideColorsVariant = false,
  "data-element-id": dataElementId,
  "data-description": dataDescription,
  "data-element-id-img": dataElementIdImg,
  "data-description-img": dataDescriptionImg,
  productCode,
  enableDataDescription,
  isCarousel = false,
  cssStyle = "default",
  noLinkImg = false,
  showBrandInVariant = false,
  showModelInVariant = false,
  callbackSizeSelector,
  optionalCta, // optional behavior on tile click (overrides default redirect to PDP, does not affect SEE ALL button)
  isVariantCartTile = false,
  tagTilesUsage, // determines which tags (badges) should be seen in the product's tiles. default is "active",
  showCodeInVariant = false,
  currentDoor,
  isStandaloneVariantTile,
  showAddToCartButton = false,
  addToCart,
  isMultidoor,
  price,
  isLoading,
  starsSkus,
  hasColorOnTop = true,
  isKeylookTile = false,
  isExtendedTile = false,
  isKeylookOrSimilarTile = false,
  hideFooterPlaceholder = false,
  borderBottomLensContainer = false,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const selectedTile = useSelector(selectSelectedTile);
  const brandGroupByBrandMap = useSelector(selectBrandGroupByBrandMap);
  const isVariant = instanceOfVariant(data);
  const displayPrices = useSelector(selectDisplayPrices);
  const [isImgLoaded, setIsImgLoaded] = useState<boolean>(false);
  const isAFAOrHelmet = checkIsAFAOrHelmet(data.productCategory);
  const isGoggles = data.productCategory && data.productCategory === "goggle";
  const isAFAOrGogglesHelmetVerticalTile = (isGoggles || isAFAOrHelmet) && !isExtendedTile;
  const techIcons = useSelector(selectTechIconContents);
  const isEmpty =
    instanceOfProduct(data) &&
    data.counterSizes === 0 &&
    data.counterColors === 0 &&
    ((data.variants && data.variants.length > 0) || hideSeeVariants);

  const hasColors = instanceOfProduct(data) && data.counterColors !== 0;
  const hasVariants = instanceOfProduct(data) && data.variants;
  const selected = selectedTile && selectedTile.uniqueID === data.uniqueID;
  const onClickSeeAllVariants = () => {
    if (instanceOfProduct(data) && data.productCode) dispatch(setSelectedTile(data));
  };

  const onClickSeeAllVariantsRecommended = () => {
    if (instanceOfVariant(data)) {
      dispatch(setSelectedTile(data));
    }
  };

  const { catalogue: catalogueRequestStatus, facets: facetsRequestStatus } = useSelector(
    selectLoadingPlp
  );

  useEffect(() => {
    if (
      (data.productCategory === "afa" || data.productCategory === "helmet") &&
      data?.brand &&
      !(data?.brand in techIcons) &&
      brandGroupByBrandMap?.[data?.brand]
    ) {
      dispatch(
        getPDPTechIconsCms({
          type: "CMChannel",
          additionalTags: ["tech-icons", brandGroupByBrandMap[data.brand]] || "",
        })
      );
    }
  }, [data]);

  const imageClassNames = clsx(
    isAFAOrHelmet && !isAFAOrGogglesHelmetVerticalTile && "plp-img-afa",
    isAFAOrGogglesHelmetVerticalTile && "plp-img-afa-vertical",
    !isAFAOrHelmet && "plp-img"
  );
  const modelCode = (data as Product).productCode;

  const handleOptionalCta = () => optionalCta?.(data);

  const redirectPdpUrl =
    instanceOfProduct(data) && data.groupVariantSeo
      ? getPagePath("/pdp" + data.groupVariantSeo)
      : getPagePath("/pdp" + data.seo?.href);

  // Lazy loading for image in Tile.
  const { containerRef, isVisible: isImgVisible } = useIntersectionObserver<HTMLDivElement>({
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  });

  useEffect(() => {
    if (!isImgLoaded && isImgVisible) setIsImgLoaded(true);
  }, [isImgVisible]);

  // If img is not available or is not focused in browser by user a placeholder will be shown in tile
  const customProgressiveImage = (
    <div ref={containerRef}>
      <CustomProgressiveImage
        type={isAFAOrHelmet ? "afa" : "glasses"}
        src={isImgLoaded && data.img ? data.img + "?impolicy=MYL_EYE&wid=262" : ""}
      />
    </div>
  );

  // if it's a standalone variant tile (ie. from Digital Window at this time)
  // and it either doesn't have a skus property, or it does but it's an empty array
  // then hide prices
  const hidePricesForStandaloneWithoutSkus =
    isStandaloneVariantTile && instanceOfVariant(data) && (!data.skus || data.skus.length === 0);

  const isCatalogueLoading =
    catalogueRequestStatus == "LOADING" && facetsRequestStatus == "LOADING";

  const renderSeeAll = (onClickHandler: () => void): JSX.Element => {
    return isCatalogueLoading ? (
      <SeeAllContainerLoader>
        <SeeAllVariantLoader />
      </SeeAllContainerLoader>
    ) : (
      <SeeAllContainer
        onClick={(e) => e.stopPropagation()} // to avoid optionalCta from affecting also SEE ALL button
      >
        <Button
          customPadding="0.938rem 1.25rem"
          size="sm"
          maxWidth={130}
          data-description="SeeAll"
          type="primary"
          onClick={onClickHandler}
        >
          {translateLabel("SEE_ALL")}
        </Button>
      </SeeAllContainer>
    );
  };

  const renderTagContainer = (): JSX.Element => {
    return (
      <TagContainer
        className={clsx(
          !isVariant && "tag-container-product",
          isAFAOrGogglesHelmetVerticalTile && "plp-tag-afa-vertical"
        )}
      >
        <TileTags
          tags={data.tags}
          usage={tagTilesUsage}
          brand={data.brand ?? ""}
          noMargins={isAFAOrGogglesHelmetVerticalTile}
          isAFAOrGogglesHelmetVertical={isAFAOrGogglesHelmetVerticalTile}
        />
        {(data?.productCategory === "afa" || data?.productCategory === "helmet") && (
          <AFAIconRender
            variant={data as Variant}
            type="vertical-tile"
            isAfaGogglesHelmetsVertical={isAFAOrGogglesHelmetVerticalTile}
            noMargins={!data?.tags?.length}
          />
        )}
      </TagContainer>
    );
  };

  return (
    <StyledTile
      hidePrices={displayPrices === "NO_PRICES" || hidePrices}
      isExtendedTile={isExtendedTile}
      className={clsx(
        "plp-tile",
        !isVariant && "tile-space-between",
        isVariant && "tile-variant",
        (isAFAOrHelmet || (isGoggles && isCarousel)) &&
          !isAFAOrGogglesHelmetVerticalTile &&
          "plp-tile-afa",
        isAFAOrGogglesHelmetVerticalTile && "plp-tile-afa-vertical",
        isStandaloneVariantTile && "tile-standalone",
        {
          selected,
        },
        cssStyle === "space-between" && "tile-space-between"
      )}
      {...(dataElementId ? { "data-element-id": dataElementId } : {})}
      {...(dataDescription ? { "data-description": dataDescription } : {})}
      {...(enableDataDescription ? { "data-description": modelCode } : {})}
      {...(optionalCta ? { onClick: handleOptionalCta } : {})}
      isSparepartsPage={hideFooterPlaceholder}
    >
      <div>
        <TileHeader
          data={data}
          isVariant={isVariant}
          url={redirectPdpUrl}
          noLinkImg={noLinkImg}
          showBrandInVariant={showBrandInVariant}
          showCodeInVariant={showCodeInVariant}
          showModelInVariant={showModelInVariant}
        />
        {noLinkImg ? (
          <ImageContainerWithoutLink className={imageClassNames}>
            {customProgressiveImage}
          </ImageContainerWithoutLink>
        ) : (
          <ImageContainer
            {...(dataElementIdImg ? { "data-element-id": dataElementIdImg } : {})}
            {...(dataDescriptionImg ? { "data-description": dataDescriptionImg } : {})}
            to={redirectPdpUrl}
            className={imageClassNames}
            isVariantCartTile={isVariantCartTile}
          >
            {customProgressiveImage}
          </ImageContainer>
        )}
        {!hideTag && !isAFAOrGogglesHelmetVerticalTile && renderTagContainer()}
      </div>
      <div>
        {!hideTag && isAFAOrGogglesHelmetVerticalTile && renderTagContainer()}
        {!isVariant && (
          <VariantContainer isEmpty={isEmpty}>
            <ColorSizeContainer>
              {instanceOfProduct(data) &&
                data.counterColors !== undefined &&
                data.counterColors !== 0 && (
                  <ColorContainer>
                    <CustomText
                      as="span"
                      fontSizePx={13}
                      color="primary"
                      font="font-bold"
                      marginRightPx={8}
                      isEllipsis
                    >
                      {data.counterColors}
                    </CustomText>
                    <MulticolorCircle />
                  </ColorContainer>
                )}
              {instanceOfProduct(data) &&
                data.counterSizes !== undefined &&
                data.counterSizes !== 0 && (
                  <>
                    <OpacityLayer />
                    <SizeEventWrapper>
                      <CustomText
                        as="span"
                        fontSizePx={13}
                        color="primary"
                        font="font-bold"
                        isEllipsis
                      >
                        {data.counterSizes}
                      </CustomText>
                      <CustomText
                        as="span"
                        fontSizePx={13}
                        color="primary"
                        marginLeftPx={8}
                        isEllipsis
                      >
                        {translateLabel("SIZE")}
                      </CustomText>
                    </SizeEventWrapper>
                  </>
                )}
            </ColorSizeContainer>
            {!hideSeeVariants && hasColors && hasVariants && renderSeeAll(onClickSeeAllVariants)}
          </VariantContainer>
        )}
        {instanceOfVariant(data) &&
          data.productCategory !== "afa" &&
          data.productCategory !== "helmet" && (
            <>
              {hasColorOnTop && (
                <div className={clsx("d-flex", hideColorsVariant && "mb-2")}>
                  <CustomText as="span" fontSizePx={13} font="font-bold" marginRightPx={8}>
                    {data.frontColorDescription?.values}
                  </CustomText>
                  <MulticolorCircle />
                </div>
              )}
              {!hideColorsVariant && (
                <LensContainer className={clsx(borderBottomLensContainer && "border-bottom")}>
                  <TileLensInfo hideHeight={borderBottomLensContainer} data={data} />
                </LensContainer>
              )}
            </>
          )}
        {!hidePrices && !hidePricesForStandaloneWithoutSkus && (
          <PriceTile
            price={price ?? data.price}
            isLoading={isLoading}
            isKeylookOrSimilarTile={isKeylookOrSimilarTile}
            isAfaGogglesHelmetsVertical={isAFAOrGogglesHelmetVerticalTile}
          />
        )}
        {instanceOfVariant(data) &&
          !hasColorOnTop &&
          data.productCategory !== "afa" &&
          data.productCategory !== "helmet" && (
            <VariantColorContainer>
              <div className={clsx("d-flex", hideColorsVariant && "mb-2")}>
                <CustomText as="span" fontSizePx={13} font="font-bold" marginRightPx={8}>
                  {data.frontColorDescription?.values}
                </CustomText>
              </div>
              {data.frontColorDescription?.values && <MulticolorCircle />}
              <>{renderSeeAll(onClickSeeAllVariantsRecommended)}</>
            </VariantColorContainer>
          )}
        {!hideFooter && (
          <FooterContainer>
            <TileFooter
              data={data}
              isVariant={isVariant}
              showSizeRadio
              isCarousel={isCarousel}
              productCode={productCode}
              door={currentDoor}
              showAddToCartButton={showAddToCartButton}
              addToCart={addToCart}
              isMultidoor={isMultidoor}
              compactMultidoorSelect
              loadingAddSize={isLoading}
              price={price ?? data.price}
              starsSkus={starsSkus}
              isAfaGogglesHelmetsVertical={isAFAOrGogglesHelmetVerticalTile}
            />
          </FooterContainer>
        )}
        {hideFooter && !showSizeRadio && !hideFooterPlaceholder && <FooterPlaceholder />}
        {showSizeRadio && isVariant && instanceOfVariant(data) && (
          <FooterContainer className={clsx(isAFAOrHelmet && "footer-container--no-padding")}>
            <SizeSelector
              skus={data.skus ?? []}
              updateSelectedSku={(uniqueID: string) =>
                callbackSizeSelector && callbackSizeSelector(uniqueID)
              }
            />
          </FooterContainer>
        )}
      </div>
    </StyledTile>
  );
};

const StyledTile = styled.div<{
  onClick?: any;
  hidePrices?: boolean;
  isSparepartsPage?: boolean;
  isExtendedTile?: boolean;
}>`
  display: flex;
  flex-direction: column;
  background-color: ${(prop) => prop.theme.palette.white};
  width: 20.31rem;
  min-height: ${(prop) =>
    prop.isSparepartsPage ? "25rem" : prop.hidePrices ? "35rem" : prop.theme.tileHeight};
  flex-shrink: 0;
  padding: 2rem;
  box-sizing: border-box;
  cursor: ${(prop) => (prop.onClick ? "pointer" : "default")};

  &.tile-variant {
    border: 0;
    background-color: ${(prop) => prop.theme.palette.white};
    min-height: 100%;
    padding: 0.938rem 1.306rem 0 1.5rem;
  }

  &.tile-space-between {
    justify-content: space-between;
  }

  &.tile-standalone {
    padding: 2rem;
  }

  &.plp-tile-afa {
    height: ${(prop) =>
      prop.isExtendedTile ? "35rem" : prop.hidePrices ? "40rem" : prop.theme.tileAFAHeight};
  }

  &.plp-tile-afa-vertical {
    min-height: ${(prop) => (prop.hidePrices ? "40rem" : prop.theme.tileAFAHeight)};
    width: 20.5rem;
  }
`;

const ImageContainer = styled(Link)<{ isVariantCartTile: boolean }>`
  display: flex;
  justify-content: center;

  &.plp-img {
    padding-top: 0.5rem;

    img {
      height: ${(prop) => (prop.isVariantCartTile ? "12.25rem" : "8.125rem")};
      width: ${(prop) => (prop.isVariantCartTile ? "16.3125rem" : "unset")};
      object-fit: ${(prop) => (prop.isVariantCartTile ? "contain" : "unset")};
    }
  }

  &.plp-img-afa {
    height: 13.5rem;
    align-items: center;
    margin: 1.25rem 0;

    img {
      padding: 1rem 0;
      width: auto;
      height: fit-content;
      max-height: 13.5rem;
      max-width: 19rem;
    }
  }

  &.plp-img-afa-vertical {
    height: 13.5rem;
    align-items: center;
    margin: 1.25rem 0 0;
    padding: 0;

    img {
      width: auto;
      height: fit-content;
      max-height: 13.5rem;
      max-width: 16.5rem;
    }
  }
`;

const ImageContainerWithoutLink = styled.div`
  display: flex;
  justify-content: center;

  &.plp-img {
    padding-top: 0.5rem;

    img {
      width: 100%;
    }
  }

  &.plp-img-afa {
    margin: 1.25rem 0;
    img {
      padding: 1rem 0;
      width: auto;
      height: 13.5rem;
      max-height: 13.5rem;
    }
  }
`;

const VariantContainer = styled.div<{ isEmpty: boolean }>`
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 1rem 0;
  min-height: 4.25rem;
  box-sizing: border-box;
  border-top: ${(props) => (props.isEmpty ? 0 : `1px solid ${props.theme.palette.gray.medium}`)};
`;

const ColorSizeContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ColorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

const VariantColorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
  border-top: 1px solid #e9eaed;

  .d-flex {
    max-width: 100px;
  }

  svg {
    align-self: center;
  }
`;

const SeeAllContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;
`;

const SeeAllContainerLoader = styled(SeeAllContainer)`
  column-gap: 0.5rem;
`;

const LensContainer = styled.div`
  border-top: 1px solid ${(props) => props.theme.palette.gray.medium};
  padding: 1rem 0;
  margin-top: 1rem;
  min-height: 4.313rem;

  &.border-bottom {
    border-bottom: 1px solid ${(props) => props.theme.palette.gray.medium};
    margin-bottom: 0.938rem;
  }
`;

const TagContainer = styled.div`
  margin: 1rem 0 0.5rem 0;
  &.tag-container-product {
    flex-grow: 1;
    min-height: 6.75rem;
    overflow: auto;
    max-height: 6.75rem;
  }

  &.plp-tag-afa-vertical {
    max-height: unset;
    min-height: unset;
    margin: 1.25rem 0;
    overflow: visible;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  border-top: 1px solid ${(props) => props.theme.palette.gray.medium};
  padding-top: 1rem;

  &.footer-container--no-padding {
    padding-top: 0;
  }
`;

const SizeEventWrapper = styled.div`
  display: flex;
`;

const OpacityLayer = styled.div`
  height: 100%;
`;

const DetailsHoverContainer = styled.div`
  padding: 0.625rem 1.625rem;
  border-radius: 0.25rem;
  background-color: ${(props) => props.theme.palette.primary};
  position: relative;

  &:before {
    content: "";
    display: inline-block;
    width: 7rem;
    height: 100%;
    position: absolute;
    right: 100%;
    bottom: 0;
    background: linear-gradient(to left, white 18%, rgba(255, 255, 255, 0) 100%);
    z-index: 2;
  }

  button {
    width: 6.563rem;
  }
`;

const FooterPlaceholder = styled.div`
  min-height: 4.75rem;
`;

export default Tile;
