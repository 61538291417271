import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ErrorObj,
  MultipleRequestStatus,
  RequestStatus,
  SetMultipleRequestStatus,
} from "../../interfaces/mainInterfaces";
import {
  MostUsedChip,
  MostUsedContent,
  MostUsedLoading,
} from "../../interfaces/mostUsedInterfaces";
import { emptyError } from "../../utils/consts";
import { privilegesMapAND, privilegesMapOR } from "../../utils/privilegesUtils";
import { computeSelectedDoor, isMultidoor } from "../../utils/userUtils";
import {
  ContextTutorialPill,
  HistoryTutorialPill,
  LegalTermsDocument,
  TutorialPill,
} from "../catalogue/catalogueInterface";
import { RootState } from "../storeConfig";
import {
  CatalogueLayout,
  CatalogueLayoutLoadingPayload,
  CatalogueLayoutPayload,
  CurrencyFormat,
  DisplayPricesOptions,
  Door,
  GetAdvancedSearchPayload,
  LoginError,
  LoginKeys,
  LoginSuccessPayload,
  OnePortalConfiguration,
  OrganizationDetails,
  PersonificationAdvFields,
  PersonificationCustomer,
  PersonificationDummy,
  PersonificationStores,
  PersonificationStoresOption,
  SavePrivilegesPayload,
  UserContextPayload,
  UserHavingPrivilege,
  UserPrivileges,
  UserState,
} from "./userInterfaces";

export const sliceName = "user";

export const initialState: UserState = {
  isLogged: false,
  justLogged: false,
  redirectUrlAfterLogin: null,
  loginKeys: null,
  username: null,
  preferredCurrency: {
    pub: "",
    opt: "",
  },
  preferredLanguageId: null,
  currency: {
    pub: "",
    opt: "",
  },
  languageId: null,
  storeIdentifier: "",
  session: "",
  displayPrices: null,
  srpOptInChoice: "",
  srpOptInChoiceOp: "",
  isDisplayPricesLoading: "IDLE",
  isSrpOptionsLoading: "IDLE",
  isSrpOptionsLoadingOp: "IDLE",
  supportedDisplayPrices: null,
  pdpLayout: "grid",
  pdpLayoutChanged: false,
  pdpLayoutLoading: "IDLE",
  plpLayout: "grid",
  plpLayoutLoading: "IDLE",
  backOfficeUser: false,
  organizationDetails: null,
  userType: null,
  email: null,
  mostUsedContent: {
    bookmarks: [],
    canEdit: false,
    unused: true,
  },

  // personification
  personificationCustomers: null,
  personificationAdvFiels: null,
  personificationStores: null,
  personificationDummy: null,

  // Status
  isLoginLoading: "IDLE",
  isKeysLoading: "IDLE",
  isForgotPasswordLoading: "IDLE",
  isChangePasswordLoading: "IDLE",
  postPersonificationStatus: "IDLE",
  getPersonificationStatus: "IDLE",
  getPrivilegesStatus: "IDLE",
  getPersonificationStoresStatus: "IDLE",
  getPersonificationDummyStatus: "IDLE",
  mostUsedLoading: { get: "IDLE", update: "IDLE" },
  getEssilorBadgePictureStatus: "IDLE",

  // multidoor
  activeDoor: null,
  selectedDoor: null,
  hasChangedDoor: false,
  isMultidoor: false,
  isStarsMultidoor: false,
  isMassiveOrderMode: false,
  multiDoors: [],
  totalDoors: null,

  // privileges
  atLeastOneTruePrivileges: [],
  userHavingPrivilege: [],
  usersPrivilege: [],
  mainDoorPrivilege: null,

  // errors
  loginError: { ...emptyError },
  forgotPasswordError: { ...emptyError },
  changePasswordError: { ...emptyError },

  // advancedSearch
  advancedSearch: null,
  advancedSearchLoading: false,

  //canBuyModelsGlobal and canBuySparepartsGlobal
  canBuyModelsGlobal: false,
  canBuySparepartsGlobal: false,

  //One portal configuration
  onePortalConfiguration: null,

  //Badge Essilor - MyAccount Menu
  badgeEssilor: {
    ambassadorCode: "",
    pictureURI: "",
  },

  //AVA and EyeCode Essilor
  isAva: false,
  isEyecode: false,

  contextItemsTutorialPillsPopup: undefined,
  legalTermsOfUseDictionary: null,
  isLegalTermsAccepted: false,
};

export const userSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    userContextSuccess: (state, action: PayloadAction<UserContextPayload>) => {
      state.username = action.payload.username;
      if (window.dtrum && window.dtrum.identifyUser) {
        //set username on dynatrace digital experience
        window.dtrum.identifyUser(action.payload?.username);
      }

      if (action.payload.globalization) {
        state.preferredCurrency = {
          opt: action.payload.globalization.preferredCurrency ?? "",
          pub: action.payload.globalization.preferredCurrencySrp ?? "",
        };
        state.currency = {
          opt: action.payload.globalization.preferredCurrency ?? "",
          pub: action.payload.globalization.preferredCurrencySrp ?? "",
        };
      }

      if (
        action.payload.globalization &&
        action.payload.globalization.preferredLanguageId !== undefined
      ) {
        state.preferredLanguageId = action.payload.globalization.preferredLanguageId;
        state.languageId = action.payload.globalization.preferredLanguageId;
      }

      if (action.payload.activeDoor) {
        state.activeDoor = action.payload.activeDoor;
      }

      const isMultidoorBool = isMultidoor(action.payload.multiDoors, action.payload.activeDoor);
      state.isMultidoor = isMultidoorBool;

      //check for multidoor stars
      if (isMultidoorBool && action.payload.isStarsMultidoor) {
        state.isStarsMultidoor = true;
      }

      if (action.payload.userType) {
        state.userType = action.payload.userType;
      }

      let isMassiveOrderMode = (isMultidoorBool && action.payload.massiveOrderMode) ?? false;
      if (isMultidoorBool && action.payload.multiDoors.length === 1) {
        isMassiveOrderMode = false;
      }
      state.isMassiveOrderMode = isMassiveOrderMode;

      if (action.payload.email) {
        state.email = action.payload.email;
      }

      if (action.payload.canBuyModels) {
        state.canBuyModelsGlobal = action.payload.canBuyModels;
      }

      if (action.payload.canBuySpareparts) {
        state.canBuySparepartsGlobal = action.payload.canBuySpareparts;
      }
    },
    saveTotalDoors: (state, action: PayloadAction<number>) => {
      state.totalDoors = action.payload;
    },
    saveMultiDoors: (state, action: PayloadAction<Door[]>) => {
      state.multiDoors = action.payload;
    },
    savePrivileges: (state, action: PayloadAction<SavePrivilegesPayload>) => {
      state.atLeastOneTruePrivileges = action.payload?.atLeastOneTrue ?? [];
      state.userHavingPrivilege = action.payload?.userHavingPrivilege ?? [];
      state.usersPrivilege = action.payload?.usersPrivilege ?? [];
      state.mainDoorPrivilege = action.payload?.mainDoorPrivilege ?? null;
    },
    setSelectedDoor: (state, action: PayloadAction<Door | null>) => {
      state.selectedDoor = action.payload;
    },
    setHasChangedDoor: (state, action: PayloadAction<boolean>) => {
      state.hasChangedDoor = action.payload;
    },
    setStoreIdentifier: (state, action: PayloadAction<string>) => {
      state.storeIdentifier = action.payload;
    },
    setJustLogged: (state, action: PayloadAction<boolean>) => {
      state.justLogged = action.payload;
    },
    loginSuccess: (state, action: PayloadAction<LoginSuccessPayload>) => {
      state.isLogged = true;
      state.justLogged = true;
      state.storeIdentifier = action.payload.storeIdentifier;
      state.session = action.payload.session;
      if (action.payload.redirectUrl) state.redirectUrlAfterLogin = action.payload.redirectUrl;
    },
    setLanguageId: (state, action: PayloadAction<number>) => {
      state.languageId = action.payload;
    },
    setDisplayPricesLoading: (state, action: PayloadAction<RequestStatus>) => {
      state.isDisplayPricesLoading = action.payload;
    },
    setSrpOptInLoading: (state, action: PayloadAction<RequestStatus>) => {
      state.isSrpOptionsLoading = action.payload;
    },
    setSrpOptInLoadingOp: (state, action: PayloadAction<RequestStatus>) => {
      state.isSrpOptionsLoadingOp = action.payload;
    },
    setDisplayPrices: (state, action: PayloadAction<DisplayPricesOptions>) => {
      state.displayPrices = action.payload;
    },
    setSrpOptInChoice: (state, action: PayloadAction<string>) => {
      state.srpOptInChoice = action.payload;
    },
    setSrpOptInChoiceOp: (state, action: PayloadAction<string>) => {
      state.srpOptInChoiceOp = action.payload;
    },
    setSupportedDisplayPrices: (state, { payload }: PayloadAction<DisplayPricesOptions[]>) => {
      state.supportedDisplayPrices = payload;
    },
    // plp and pdp layouts
    setCataloguePageLayout: (state, action: PayloadAction<CatalogueLayoutPayload>) => {
      switch (action.payload.pageType) {
        case "pdp":
          state.pdpLayout = action.payload.layout;
          break;
        case "plp":
          state.plpLayout = action.payload.layout;
          break;
      }
    },
    setContextTutorialPillsPopup: (state, action: PayloadAction<ContextTutorialPill | null>) => {
      state.contextItemsTutorialPillsPopup = action.payload;
    },
    setLegalTermsOfUseDictionary: (state, action: PayloadAction<LegalTermsDocument | null>) => {
      state.legalTermsOfUseDictionary = action.payload;
    },
    setIsLegalTermsAccepted: (state, action: PayloadAction<boolean>) => {
      state.isLegalTermsAccepted = action.payload;
    },
    setCataloguePageLayoutLoading: (
      state,
      action: PayloadAction<CatalogueLayoutLoadingPayload>
    ) => {
      switch (action.payload.pageType) {
        case "pdp":
          state.pdpLayoutLoading = action.payload.status;
          break;
        case "plp":
          state.plpLayoutLoading = action.payload.status;
          break;
      }
    },

    setCataloguePdpLayoutChanged: (state, action: PayloadAction<boolean>) => {
      state.pdpLayoutChanged = action.payload;
    },

    // personification
    savePersonificationStores: (state, action: PayloadAction<PersonificationStores[] | null>) => {
      state.personificationStores = action.payload;
    },
    setPersonificationCustomers: (
      state,
      action: PayloadAction<PersonificationCustomer[] | null>
    ) => {
      state.personificationCustomers = action.payload;
    },
    setPersonificationCustomersStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getPersonificationStatus = action.payload;
    },
    setPostPersonificationStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.postPersonificationStatus = action.payload;
    },
    saveGetPersonificationStoresStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getPersonificationStoresStatus = action.payload;
    },
    savePersonificationDummy: (state, action: PayloadAction<PersonificationDummy | null>) => {
      state.personificationDummy = action.payload;
    },
    saveGetPersonificationDummyStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getPersonificationDummyStatus = action.payload;
    },
    // login
    setIsLogged: (state, action: PayloadAction<boolean>) => {
      state.isLogged = action.payload;
    },
    setLoginKeys: (state, action: PayloadAction<LoginKeys>) => {
      state.loginKeys = action.payload;
    },
    saveIsBackOfficeUser: (state, action: PayloadAction<boolean>) => {
      state.backOfficeUser = action.payload;
    },
    setLoginLoading: (state, action: PayloadAction<RequestStatus>) => {
      state.isLoginLoading = action.payload;
    },
    setKeysLoading: (state, action: PayloadAction<RequestStatus>) => {
      state.isKeysLoading = action.payload;
    },
    setGetPrivilegesStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getPrivilegesStatus = action.payload;
    },
    setLoginError: (state, action: PayloadAction<LoginError>) => {
      state.loginError = action.payload;
    },
    resetLoginError: (state) => {
      state.loginError = initialState.loginError;
    },
    // forgot password
    setForgotPasswordLoading: (state, action: PayloadAction<RequestStatus>) => {
      state.isForgotPasswordLoading = action.payload;
    },
    setForgotPasswordError: (state, action: PayloadAction<ErrorObj>) => {
      state.forgotPasswordError = action.payload;
    },
    resetForgotPasswordError: (state) => {
      state.forgotPasswordError = initialState.forgotPasswordError;
    },
    // change password
    setChangePasswordLoading: (state, action: PayloadAction<RequestStatus>) => {
      state.isChangePasswordLoading = action.payload;
    },
    setChangePasswordError: (state, action: PayloadAction<ErrorObj>) => {
      state.changePasswordError = action.payload;
    },
    saveAtLeatOnePrivileges: (state, action: PayloadAction<string[]>) => {
      state.atLeastOneTruePrivileges = action.payload;
    },
    resetChangePasswordError: (state) => {
      state.changePasswordError = initialState.changePasswordError;
    },
    setAdvancedSearch: (state, action: PayloadAction<GetAdvancedSearchPayload>) => {
      state.advancedSearch = action.payload;
    },
    setAdvancedSearchLoading: (state, action: PayloadAction<boolean>) => {
      state.advancedSearchLoading = action.payload;
    },
    setOrganizationDetails: (state, action: PayloadAction<OrganizationDetails>) => {
      state.organizationDetails = action.payload;
    },
    setMostUsedContent: (state, action: PayloadAction<MostUsedContent>) => {
      state.mostUsedContent = action.payload;
    },
    setMostUsedChips: (state, action: PayloadAction<MostUsedChip[]>) => {
      // TODO: Remove this when we'll have api
      state.mostUsedContent.bookmarks = action.payload;
    },
    //OnePortal configuration
    setOnePortalConfiguration: (state, action: PayloadAction<OnePortalConfiguration>) => {
      state.onePortalConfiguration = action.payload;
    },
    setMostUsedLoading: (
      state,
      action: PayloadAction<SetMultipleRequestStatus<MostUsedLoading>>
    ) => {
      state.mostUsedLoading = {
        ...state.mostUsedLoading,
        [action.payload.type]: action.payload.value,
      };
    },
    setEssilorBadgeCode: (state, action: PayloadAction<string>) => {
      state.badgeEssilor.ambassadorCode = action.payload;
    },
    saveGetEssilorBadgePictureStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getEssilorBadgePictureStatus = action.payload;
    },
    saveEssilorBadgePicture: (state, action: PayloadAction<string>) => {
      state.badgeEssilor.pictureURI = action.payload;
    },
    resetState: () => initialState,
    logout: () => initialState,
  },
});

export const {
  loginSuccess,
  setJustLogged,
  setIsLogged,
  setLoginKeys,
  userContextSuccess,
  savePrivileges,
  setSelectedDoor,
  setHasChangedDoor,
  saveIsBackOfficeUser,
  setStoreIdentifier,
  setLanguageId,
  setDisplayPrices,
  setSrpOptInChoice,
  setSrpOptInChoiceOp,
  setSupportedDisplayPrices,
  setDisplayPricesLoading,
  setSrpOptInLoading,
  setSrpOptInLoadingOp,
  setCataloguePageLayout,
  setContextTutorialPillsPopup,
  setCataloguePageLayoutLoading,
  setCataloguePdpLayoutChanged,
  setPersonificationCustomers,
  setPostPersonificationStatus,
  setPersonificationCustomersStatus,
  setForgotPasswordError,
  setLoginLoading,
  setKeysLoading,
  setLoginError,
  resetLoginError,
  setForgotPasswordLoading,
  resetForgotPasswordError,
  setChangePasswordError,
  setChangePasswordLoading,
  resetChangePasswordError,
  resetState,
  saveAtLeatOnePrivileges,
  setGetPrivilegesStatus,
  setAdvancedSearch,
  setAdvancedSearchLoading,
  setOrganizationDetails,
  setMostUsedContent,
  setMostUsedChips,
  setMostUsedLoading,
  savePersonificationStores,
  saveGetPersonificationStoresStatus,
  savePersonificationDummy,
  saveGetPersonificationDummyStatus,
  saveTotalDoors,
  saveMultiDoors,
  setOnePortalConfiguration,
  setEssilorBadgeCode,
  saveEssilorBadgePicture,
  saveGetEssilorBadgePictureStatus,
  setLegalTermsOfUseDictionary,
  setIsLegalTermsAccepted,
} = userSlice.actions;

export const selectMostUsedContent = (state: RootState): MostUsedContent =>
  state.user.mostUsedContent;
export const selectMostUsedLoading = (state: RootState): MultipleRequestStatus<MostUsedLoading> =>
  state.user.mostUsedLoading;
export const selectIsLogged = (state: RootState): boolean => state.user.isLogged;
export const selectJustLogged = (state: RootState): boolean => state.user.justLogged;
export const selectRedirectUrlAfterLogin = (state: RootState): string | null =>
  state.user.redirectUrlAfterLogin;
export const selectUserName = (state: RootState): string | null => state.user.username;
export const selectUserCountry = (state: RootState): string | null => {
  const userValues = state.user.username?.split(".") ?? ["", ""];
  const userCountry = userValues[userValues.length - 1];
  return userCountry;
};
export const selectUserId = (state: RootState): string | null => {
  const userValues = state.user.username?.split(".") ?? ["", ""];
  const userId = userValues[userValues.length - 2];
  return userId;
};
export const selectLanguageId = (state: RootState): number | null => state.user.languageId;
export const selectStoreIdentifier = (state: RootState): string => state.user.storeIdentifier;
export const selectLocale = (state: RootState): string => {
  const supportedLanguages = state.store.supportedLanguages;
  const currentLanguageId = state.user.languageId;
  const currentLocale = supportedLanguages.filter((lang) => lang.languageId === currentLanguageId);

  if (currentLocale.length > 0) return currentLocale[0]?.locale.replace("_", "-");
  else return "";
};
export const selectUserType = (state: RootState): string | null => state.user.userType;

// export const selectIsMainUser = createSelector(selectUserType, (userType) => {
//   return userType && userType === "Mainuser";
// });

export const selectIsSubuser = createSelector(selectUserType, (userType) => {
  return userType && userType === "Subuser";
});

export const selectUserState = (state: RootState): UserState => {
  return state.user;
};

export const selectCurrencyFormat = (state: RootState): CurrencyFormat => {
  return {
    currency: {
      pub: state.user.currency.pub,
      opt: state.user.currency.opt,
    },
    locale: selectLocale(state),
  };
};

export const selectIsMultidoor = (state: RootState): boolean => {
  return state.user.isMultidoor;
};

export const selectIsStarsMultidoor = (state: RootState): boolean => {
  return state.user.isStarsMultidoor;
};

export const selectHasChangedDoor = (state: RootState): boolean => {
  return state.user.hasChangedDoor;
};

export const selectMultiDoors = (state: RootState): Door[] => {
  return state.user.multiDoors;
};

export const selectIsMassiveOrderModeActive = (state: RootState): boolean => {
  return state.user.isMassiveOrderMode;
};

export const selectTotalDoors = (state: RootState): number | null => {
  return state.user.totalDoors;
};

export const selectDisplayPrices = (state: RootState): DisplayPricesOptions | null => {
  return state.user.displayPrices;
};

export const selectDisplayPricesLoading = (state: RootState): RequestStatus => {
  return state.user.isDisplayPricesLoading;
};

export const selectSrpOptInChoice = (state: RootState): string => {
  return state.user.srpOptInChoice;
};

export const selectSrpOptInChoiceOp = (state: RootState): string => {
  return state.user.srpOptInChoiceOp;
};

export const selectSrpOptInChoiceLoading = (state: RootState): RequestStatus => {
  return state.user.isSrpOptionsLoading;
};

export const selectSrpOptInChoiceLoadingOp = (state: RootState): RequestStatus => {
  return state.user.isSrpOptionsLoadingOp;
};

export const selectGetPrivilegesStatus = (state: RootState): RequestStatus => {
  return state.user.getPrivilegesStatus;
};

export const selectSupportedDisplayPrices = (state: RootState): DisplayPricesOptions[] | null => {
  return state.user.supportedDisplayPrices;
};

export const selectPdpPageLayout = (state: RootState): CatalogueLayout => {
  return state.user.pdpLayout;
};

export const selectPdpPageLayoutChanged = (state: RootState): boolean => {
  return state.user.pdpLayoutChanged;
};

export const selectPlpPageLayout = (state: RootState): CatalogueLayout => {
  return state.user.plpLayout;
};

export const selectLoginKeys = (state: RootState): LoginKeys | null => state.user.loginKeys;

export const selectIsLoginLoading = (state: RootState): RequestStatus => state.user.isLoginLoading;

export const selectIsKeysLoading = (state: RootState): RequestStatus => state.user.isKeysLoading;

export const selectActiveDoor = (state: RootState): Door | null => state.user.activeDoor;

export const selectMainDoorPrivileges = (state: RootState): UserPrivileges | null =>
  state.user.mainDoorPrivilege;

export const selectUsernamePos = (state: RootState): string => {
  if (state.user.backOfficeUser) {
    return state.user.activeDoor?.username ?? "";
  } else {
    return state.user.username ?? "";
  }
};

export const selectSelectedDoor = createSelector(
  (state: RootState) => state.user.selectedDoor,
  selectActiveDoor,
  selectMultiDoors,
  (selectedDoor, activeDoor, multiDoors): Door | null => {
    return computeSelectedDoor(selectedDoor, activeDoor, multiDoors);
  }
);

export const selectSelectedDoorIfStarsMultidoor = createSelector(
  selectIsStarsMultidoor,
  selectSelectedDoor,
  (isStarsMultidoor, selectedDoor): Door | undefined => {
    return isStarsMultidoor && selectedDoor ? selectedDoor : undefined;
  }
);

export const selectLoginError = (state: RootState): LoginError => state.user.loginError;

export const selectIsForgotPasswordLoading = (state: RootState): RequestStatus =>
  state.user.isForgotPasswordLoading;

export const selectForgotPasswordError = (state: RootState): ErrorObj =>
  state.user.forgotPasswordError;

export const selectIsChangePasswordLoading = (state: RootState): RequestStatus =>
  state.user.isChangePasswordLoading;

export const selectChangePasswordError = (state: RootState): ErrorObj =>
  state.user.changePasswordError;

export const selectSession = (state: RootState): string => {
  return state.user.session;
};

/////////////////// personification
export const selectPersonificationStores = (state: RootState): PersonificationStores[] | null => {
  return state.user.personificationStores;
};

export const selectPersonificationStoresOptions = createSelector(
  selectPersonificationStores,
  (stores): PersonificationStoresOption[] | null => {
    return stores
      ? stores.map((_) => {
          return {
            label: _.description,
            value: _.storeId,
            storeIdentifier: _.identifier,
            locale: _.locale,
            defaultLanguage: _.defaultLanguage,
          };
        })
      : null;
  }
);

export const selectPersonificationCustomers = (
  state: RootState
): PersonificationCustomer[] | null => {
  return state.user.personificationCustomers;
};
export const selectPersonificationAdvFields = (
  state: RootState
): PersonificationAdvFields | null => {
  return state.user.personificationAdvFiels;
};

export const selectPostPersonificationStatus = (state: RootState): RequestStatus => {
  return state.user.postPersonificationStatus;
};

export const selectGetPersonificationStatus = (state: RootState): RequestStatus => {
  return state.user.getPersonificationStatus;
};

export const selectGetPersonificationStoresStatus = (state: RootState): RequestStatus => {
  return state.user.getPersonificationStoresStatus;
};

export const selectPersonificationDummy = (state: RootState): PersonificationDummy | null => {
  return state.user.personificationDummy;
};

export const selectGetPersonificationDummyStatus = (state: RootState): RequestStatus => {
  return state.user.getPersonificationDummyStatus;
};

/////////////////// privileges

export const selectAtLeastOneTruePrivileges = (state: RootState): string[] =>
  state.user.atLeastOneTruePrivileges;

export const selectUserHavingPrivilege = (state: RootState): UserHavingPrivilege[] =>
  state.user.userHavingPrivilege;

export const selectUsersPrivileges = (state: RootState): UserPrivileges[] =>
  state.user.usersPrivilege;

export const selectAdvancedSearch = (state: RootState): GetAdvancedSearchPayload | null =>
  state.user.advancedSearch;

export const selectAdvancedSearchLoading = (state: RootState): boolean =>
  state.user.advancedSearchLoading;

export const selectIsBackOfficeUser = (state: RootState): boolean => state.user.backOfficeUser;

export const selectOrganizationDetails = (state: RootState): OrganizationDetails | null =>
  state.user.organizationDetails;

export const selectDefaultEmail = (state: RootState): string | null => state.user.email;

export const selectUserHasStarsUx = createSelector(
  selectUsersPrivileges,
  selectActiveDoor,
  (usersPrivileges, activeDoor): boolean => {
    const users = usersPrivileges.filter(
      (user) => user.orgentityName === activeDoor?.orgentityName
    );

    if (users.length > 0) {
      return privilegesMapAND.STARS.every((_) => users?.[0]?.privileges.includes(_));
    } else {
      return false;
    }
  }
);

export const selectDoorIdsWithStarsPrivilege = createSelector(
  selectUsersPrivileges,
  selectMultiDoors,
  (usersPrivileges, multidoors): string[] => {
    const users = usersPrivileges.filter((user) =>
      privilegesMapAND.STARS.every((_) => user.privileges.includes(_))
    );

    const doorIds: string[] = [];
    users.forEach((_) => {
      const doorId = multidoors.find((door) => door?.orgentityName === _?.orgentityName)
        ?.orgentityId;
      if (doorId) doorIds.push(doorId);
    });

    return doorIds;
  }
);

export const selectDoorsForMultidoorAddSize = createSelector(
  selectUsersPrivileges,
  selectMultiDoors,
  selectIsStarsMultidoor,
  (usersPrivileges, multiDoors, isStarsMultidoor): Door[] => {
    const usersWithPrivilege = usersPrivileges.filter((user) =>
      isStarsMultidoor
        ? privilegesMapOR.CATALOGUE.some((_) => user.privileges.includes(_))
        : privilegesMapAND.ADD_TO_CART.every((_) => user.privileges.includes(_))
    );

    const doors: Door[] = [];
    multiDoors.forEach((door) => {
      const hasPrivilege = usersWithPrivilege.find((_) => door?.orgentityName === _?.orgentityName);
      if (hasPrivilege) doors.push(door);
    });

    return doors;
  }
);

export const selectIsNotMigrated = createSelector(
  selectIsBackOfficeUser,
  selectActiveDoor,
  (backOfficeUser, activeDoor) => {
    if (backOfficeUser && activeDoor?.isMigrated === false) {
      return true;
    }
    return false;
  }
);

export const selectCanBuyModelsGlobal = (state: RootState): boolean => {
  return state.user.canBuyModelsGlobal;
};

export const selectCanBuySparepartsGlobal = (state: RootState): boolean => {
  return state.user.canBuySparepartsGlobal;
};

export const selectOnePortalConfiguration = (state: RootState): OnePortalConfiguration | null => {
  return state.user.onePortalConfiguration;
};

export const selectEssilorBadgeCode = (state: RootState): string => {
  return state.user.badgeEssilor.ambassadorCode;
};

export const selectEssilorBadgePicture = (state: RootState): string => {
  return state.user.badgeEssilor.pictureURI;
};

export const selectTutorialPillsPopup = (
  state: RootState
): ContextTutorialPill | null | undefined => state.user.contextItemsTutorialPillsPopup;

export const selectLegalTermsOfUseDictionary = (state: RootState): LegalTermsDocument | null =>
  state.user.legalTermsOfUseDictionary;

export const selectIsLegalTermsAccepted = (state: RootState): boolean =>
  state.user.isLegalTermsAccepted;

export default userSlice.reducer;
