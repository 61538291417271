import { cloneDeep } from "lodash";
import { CustomOptions } from "../components/styled-UI/CustomSelect";
import { Suggestion } from "../store/search/searchInterfaces";
import { Document } from "../store/documents/documentsInterfaces";
import { FontFamilies } from "../store/store/storeInterfaces";
import { Attribute, Sku } from "../interfaces/productInterface";
import { SparePartsSku } from "../store/aftersales/aftersalesInterface";
import { getAttributeValues } from "./productUtils";

/* REGEX */

export const phoneRegExp = /^([\(\)\+0-9\s\-\#]+)$/; // Basic Regex to check for phone
export const phoneRegExpNotRequired = /^([\(\)\+0-9\s\-\#]*)$/; // Basic Regex to check for phone, not required (accept empty string)
export const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const onlyNumericRegExp = /^\d+$/;
export const onlyNumericWithSpaceRegExp = /^[\d\s]+$/;
export const onlyAlphabeticRegExp = /^[A-Za-z]+$/;
export const onlyAlphabeticWithSpaceRegExp = /^[A-Za-z\s]+$/;
export const onlyAlphabeticWithSpaceAndAccentsRegExp = /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/;
export const alphanumericWithSpaceRegExp = /^[\w\-\s]+$/;
export const alphanumericWithSpaceAndAccentsRegExp = /^[ a-zA-ZÀ-ÿ\+0-9\u00f1\u00d1]*$/;
export const monthRegExp = /^(1[012]|0?[1-9])$/;
export const numWith2DecimalsRegExp = /^[0-9]+[.]{0,1}[0-9]{0,2}$/;
export const creditCardNumberRegexps = {
  Amex: {
    tag: "amex",
    pattern: /^3[47]/,
  },
  Visa: {
    tag: "visa",
    pattern: /^4/,
  },
  Mastercard: {
    tag: "mastercard",
    pattern: /^5[0-5]/,
  },
  Discover: {
    tag: "discover",
    pattern: /^6([045]|22)/,
  },
};
export const anyCreditCardNumberRegExp = /^(3[47])|(4)|(5[0-5])|(6([045]|22))/;

export const regexFromStr = (regStr: string): RegExp | undefined => {
  const match = /^\/(.*)\/([a-z]*)$/.exec(regStr);
  if (match) return new RegExp(match[1], match[2]);
};

/**
 *  from hello-world to helloWorld
 *
 * @param {string} item
 * @return {*}  {string}
 */
export const areEqualString = (string1: string, string2: string): boolean => {
  const cleanString1 = string1.replace("-", "").replace("_", "").toLowerCase();
  const cleanString2 = string2.replace("-", "").replace("_", "").toLowerCase();

  return cleanString1 === cleanString2;
};

/**
 *  from hello-world to helloWorld
 *
 * @param {string} item
 * @return {*}  {string}
 */
export const fromDashToCamelCase = (item: string): string => {
  const subStringArray = item.split("-");
  const camelCaseString = subStringArray
    .map((element, i) => {
      if (i > 0) {
        return element.charAt(0).toUpperCase() + element.substring(1);
      } else {
        return element;
      }
    })
    .join("");

  return camelCaseString;
};

/**
 * from helloWorld to HELLO_WORLD
 * @param {string} item
 * @return {*}  {string}
 */
export const fromCamelCaseToUnderscore = (item: string): string => {
  return item
    .split(/(?=[A-Z])/)
    .join("_")
    .toUpperCase();
};

/**
 * from helloWorld to Hello world
 * @param {string} item
 * @return {*}  {string}
 */
export const fromCamelCaseToSpaced = (item: string): string => {
  return capitalizeFirstLetter(item.split(/(?=[A-Z])/).join(" "));
};

/**
 * Capitalize string and string containing a list (es. item,item)
 *
 * @param {(string | undefined)} string
 * @return {*}  {string}
 */
export const capitalizeFirstLetter = (string: string | undefined): string => {
  if (string && string.includes(",")) {
    const firstUpperCase = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    const stringArray = firstUpperCase.split(",");
    return stringArray.reduce((tot, _) => {
      return tot + "," + _.charAt(0).toUpperCase() + _.slice(1).toLowerCase();
    });
  }
  return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : "";
};

export const capitalizeFirstLetterForEachWord = (str: string): string => {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

//get suggested terms to autocomplete searches
export const getSuggestionString = (
  suggestions: Suggestion[],
  currentText: string,
  index: number
): string[] | null => {
  if (suggestions && suggestions[index].term.includes(`${String(currentText)}`)) {
    const tempStrg = String(suggestions && suggestions[index].term);
    const temp = tempStrg.split(`${String(currentText)}`);
    return temp;
  } else return null;
};

// repace {str} in a string with JSX element
export const replaceStringWithElement = (
  text: string,
  str: string,
  element: JSX.Element
): (JSX.Element | string)[] => {
  const index = 1;
  const textArray: (JSX.Element | string)[] = text.split(`{${str}}`);
  return [...textArray.slice(0, index), element, ...textArray.slice(index)];
};

// repace {keys} in a string with JSX elements
export const replaceKeysWithElements = (
  text: string,
  elements: { [key: string]: JSX.Element }
): (JSX.Element | string)[] => {
  return Object.entries(elements).reduce((acc: (JSX.Element | string)[], [key, element]) => {
    if (acc.length === 0) {
      const textArray: (JSX.Element | string)[] = text.split(`{${key}}`);
      if (textArray.length > 1) return [...textArray.slice(0, 1), element, ...textArray.slice(1)];
    } else {
      const indexEl = acc.findIndex((a) => typeof a === "string" && a.includes(`{${key}}`));
      const textArray: (JSX.Element | string)[] = (acc[indexEl] as string).split(`{${key}}`);
      if (textArray.length > 1) {
        return [
          ...acc.slice(0, indexEl),
          ...textArray.slice(0, 1),
          element,
          ...textArray.slice(1),
          ...acc.slice(indexEl + 1),
        ];
      }
    }
    return acc;
  }, []);
};

/**
 * Returns an array of value-label pairs from an array of values, where the labels are the values' translations.
 * Requires the translating function to be passed as parameter.
 *
 * @param {string[]} keys
 * @param {(key: string) => string} translatingFunc
 * @return {*}  {CustomOptions[]}
 */
export const mapTranslatedKeys = (
  keys: string[],
  translatingFunc: (key: string) => string,
  prefix?: string,
  suffix?: string
): CustomOptions[] => {
  return keys.map((key) => {
    const labelKey = (prefix ?? "") + key + (suffix ?? "");
    return { value: key, label: translatingFunc(labelKey) };
  });
};

export const stripHtmlTags = (string: string): string => {
  return string.replace(/<\/?[^>]+(>|$)/g, "");
};

/**
 * Split array into chunks of specified size, ie. an array of arrays T[]
 *
 * @template T
 * @param {T[]} array
 * @param {number} chunkSize
 * @return {*}  {T[][]}
 */
export const splitArrayInChunks = <T extends unknown>(array: T[], chunkSize: number): T[][] => {
  const arrayCopy = cloneDeep(array);
  const finalArray: T[][] = [];

  while (arrayCopy.length > 0) finalArray.push(arrayCopy.splice(0, chunkSize));

  return finalArray;
};

/**
 * Partition any array into two based on filter function passed as parameter
 *
 * @param {any[]} array
 * @param {(value: any) => boolean} filter
 * @return {*}  {any[]}
 */

export const partitionArray = <T extends unknown>(
  array: T[],
  filter: (value: T) => boolean
): T[][] => {
  const pass: T[] = [];
  const fail: T[] = [];

  array?.forEach((item) => (filter(item) ? pass : fail).push(item));

  return [pass, fail];
};

/**
 * Adds a newArray to an originalArray in the specified position
 * Does NOT mutate originalArray
 *
 * @param {any[]} originalArray
 * @param {any[]} newArray
 * @param {number} position
 * @return {*}  {any[]}
 */
export const insertAtInArray = <T>(originalArray: T[], newArray: T[], position: number): T[] => {
  const arrayCopy = cloneDeep(originalArray);
  arrayCopy.splice(position, 0, ...newArray);
  return arrayCopy;
};

/**
 * Given array of elements T, group them based on the value returned by function getKey.
 *
 * @template T
 * @template K
 * @param {T[]} list
 * @param {(item: T) => K} getKey
 * @return {*}  {Record<K, T[]>}
 */
export const groupArrayIntoObject = <T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K
): Record<K, T[]> => {
  const reduced = list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

  return reduced;
};

/**
 * Given array of elements T, group them based on the value returned by function getKey.
 * Then return an array of arrays T[], one for each group.
 *
 * @template T
 * @template K
 * @param {T[]} list
 * @param {(item: T) => K} getKey
 * @return {*}  {T[][]}
 */
export const groupArrayIntoArray = <T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K
): T[][] => {
  const object = groupArrayIntoObject<T, K>(list, getKey);
  return Object.entries(object).map(([key, array]) => array) as T[][];
};

/**
 * Check if given variable is an object (not, array or null)
 *
 * @param {*} item
 * @return {*}  {boolean}
 */
export const checkIfIsObject = (item: unknown): boolean => {
  return typeof item === "object" && !Array.isArray(item) && item !== null;
};

/**
 * Mapper for pin validation errors, get the status from service and return the label
 *
 * @param  {string} message
 * @returns string
 */
export const mapPinErrorMessage = (message: string): string => {
  const label = "";
  switch (message) {
    case "WRONG_PIN":
      return "POPUP_PIN_WRONG_PIN";
    case "NOT_FOUND":
      return "POPUP_PIN_NOT_FOUND";
    case "USER_NOT_ENABLED":
      return "POPUP_PIN_USER_NOT_ENABLED";
    case "TEMPORARY_PIN":
      return "POPUP_PIN_TEMPORARY_PIN";
    case "PERMANENT_PIN":
      return "POPUP_PIN_PERMANENT_PIN";
    default:
      return message;
  }
  return label;
};
/**
 * RegEx function to check if a string contains only number digits
 * @param  {string} string
 * @returns boolean
 */
export const checkNumericalDigits = (string: string): boolean => {
  if (/^(0|[1-9][0-9]*)$/.test(string)) {
    return true;
  } else {
    return false;
  }
};
/**
 * RegEx function to check if a string contains all the same number
 * @param  {string} string
 * @returns boolean
 */
export const checkSameDigitsInsideNumber = (string: string): boolean => {
  if (!/^([0-9])\1*$/.test(string)) {
    return true;
  } else {
    return false;
  }
};

/**
 * RegEx function to check if a string contains all the same number
 * @param  {string} string
 * @returns boolean
 */
export const composeUrlWithParams = (
  url: string,
  params: { key: string; value: string }[]
): string => {
  const baseUrl = new URLSearchParams();

  params.forEach((param) => {
    baseUrl.append(param.key, param?.value);
  });

  const symbol = url.includes("?") ? "&" : "?";
  return url + symbol + baseUrl.toString();
};

export const downloadURI = (uri: string, name?: string): void => {
  const link = document.createElement("a");
  if (name) link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadMultipleURI = (files: { uri: string; name?: string }[]): void => {
  function downloadNextUri(i: number) {
    if (i >= files.length) return;

    const link = document.createElement("a");
    link.href = files[i].uri;
    link.target = "_parent";
    const name = files[i].name;
    if (name) link.download = name;

    document.body.appendChild(link); // add anchor to the doc for click to work.
    link.click(); // click on the anchor
    document.body.removeChild(link); // delete the temporary link.

    // download the next file with a small timeout, which is necessary otherwise it will only download the first file
    setTimeout(function () {
      downloadNextUri(i + 1);
    }, 1000);
  }

  downloadNextUri(0); // initiate the first download
};

export const base64toBlob = (b64Data: string, contentType = "", sliceSize = 512): Blob => {
  const byteCharacters = atob(b64Data);

  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const arrayBufferToBlob = (data: string, contentType = ""): Blob => {
  const blob = new Blob([data], { type: contentType });
  return blob;
};

export const downloadBlobFile = (blob: Blob, filename: string, extension: string): void => {
  const fileName = `${filename}.${extension}`;
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement("a");
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const convertBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      if (fileReader.result) resolve((fileReader.result as string).split(",")[1]);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const asyncConvertBase64 = async (file: File, callback: (base64: string) => void) => {
  const base64 = await convertBase64(file);

  callback(base64 as string);
};

export const mapArrayToOptionsArray = (
  array: any[],
  labelFields: string[],
  valueField: string
): {
  label: string;
  value: string;
}[] => {
  return array.map((option) => {
    let label = "";
    labelFields.forEach((_, index) => {
      if (index !== 0) {
        label += " - ";
      }
      label += option[_];
    });

    return {
      label,
      value: option[valueField],
      ...option,
    };
  });
};

export const printNonAdmittedSpeciaChar = (strToTest: string): string => {
  const format = /[ `!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?~]/;
  const regex = new RegExp(format, "g");

  regex.test(strToTest);

  const errorI = regex.lastIndex;

  if (errorI === 0) {
    return "";
  }

  return strToTest[regex.lastIndex - 1];
};

export const formatBytes = (bytes: string, decimals = 2): string => {
  const number = parseFloat(bytes);
  if (number === 0) return "0 Bytes";
  if (isNaN(number)) return "-";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(number) / Math.log(k));
  return parseFloat((number / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

/*
Check if target array (array B) is all included in array A
*/
export function checkIfArrayBInArrayA<T>(arrayA: T[], arrayB: T[]): boolean {
  return arrayB.every((v) => arrayA.includes(v));
}

export const currencySymbol = (currency: string, currencyLocale: string): string => {
  const currencyRegex = new RegExp(
    "[$\xA2-\xA5\u058F\u060B\u09F2\u09F3\u09FB\u0AF1\u0BF9\u0E3F\u17DB\u20A0-\u20BD\uA838\uFDFC\uFE69\uFF04\uFFE0\uFFE1\uFFE5\uFFE6]",
    "g"
  );

  return (
    new Intl.NumberFormat(currencyLocale, {
      style: "currency",
      currency: currency,
    })
      .format(1)
      .match(currencyRegex)?.[0] || ""
  );
};

/**
 * Function to scroll to element in div ROOT
 * Can be used with id or ref
 * @param {string} [id]
 * @param {*} [ref=null]
 * @return {*}  {void}
 */
export const scrollTo = (id?: string, ref: any = null): void => {
  // decide what type of reference that is
  // if neither ref or id is provided  set element to null
  const element = ref ? ref.current : id ? document.getElementById(id) : null;

  if (!element) {
    // log error if the reference passed is invalid
    console.error(`Invalid element, are you sure you've provided element id or react ref?`);
    return;
  }

  const header = document.getElementById("header")?.clientHeight ?? 0;
  const margin = 32;

  // the position of the root element before the user clicks the button
  const scrollOfRoot = document.getElementById("root")?.scrollTop ?? 0;
  // the position of the element before the user clicks the button
  const scrollEl = element?.getBoundingClientRect()?.top;
  const scrollTo = scrollOfRoot + scrollEl - header - margin;

  // const offsetOfElement = element.offsetTop;
  // const scrollTo = offsetOfElement - header - margin;
  // console.log(id, "id");
  // console.log(element, "element");
  // console.log(scrollOfRoot, "scrollOfRoot");
  // console.log(offsetOfElement, "offsetOfElement");
  // console.log(scrollTo, "scrollTo");
  // console.log(scrollTo2, "scrollTo2");
  // console.log(scrollEl, "getBoundingClientRect");

  document.getElementById("root")?.scrollTo({
    left: 0,
    top: scrollTo,
    behavior: "smooth",
  });
};

export const validateEmail = (email: string): boolean => {
  if (emailRegExp.test(email)) {
    return true;
  } else return false;
};

export const mergeAlternateArray = <T, J>(array1: T[], array2: J[]): (T | J)[] => {
  const result = [];
  const l = Math.min(array1.length, array2.length);

  for (let i = 0; i < l; i++) {
    result.push(array1[i], array2[i]);
  }
  result.push(...array1.slice(l), ...array2.slice(l));
  return result;
};

export const scrollToTop = (smooth = true): void => {
  document.getElementById("root")?.scrollTo({
    top: 0,
    left: 0,
    behavior: smooth ? "smooth" : "auto",
  });
};

export const detectScrollDirection = (
  lastScrollTop: number
): {
  direction: "up" | "down";
  scroll: number;
  newScrollTop: number;
} => {
  const scroll = window.pageYOffset || document.getElementById("root")?.scrollTop || 0;
  let direction: "up" | "down";

  if (scroll > lastScrollTop) {
    // downscroll code
    // console.log("scroll down");
    direction = "down";
  } else {
    // upscroll code
    // console.log("scroll up");
    direction = "up";
  }

  const newScrollTop = scroll <= 0 ? 0 : scroll;

  // return direction and how much is scrolled
  return {
    direction,
    scroll,
    newScrollTop,
  };
};

/**
 * Format a number as price given the currency and locale.
 * Returns null is no value is provided.
 *
 * @param {(number | undefined)} value
 * @param {string} currency
 * @param {string} locale
 * @return {*}  {(string | null)}
 */
export const formatCurrency = (
  value: number | undefined | null,
  currency: string,
  locale: string
): string | null => {
  if (value == undefined || value == null) return null;

  let formattedValue = null;
  try {
    //MANUAL FIX FOR SINGAPORE, INDONESIA, MALESIA CURRENCY
    switch (currency) {
      case "SGD":
        const tempFormattedValueSGD = new Intl.NumberFormat(locale, {
          style: "decimal",
        });
        formattedValue = "S$" + tempFormattedValueSGD.format(Number(value));
        break;
      case "IDR":
        const tempFormattedValueIDR = new Intl.NumberFormat(locale, {
          style: "decimal",
        });
        formattedValue = "Rp" + tempFormattedValueIDR.format(Number(value));
        break;
      case "MYR":
        const tempFormattedValueMYR = new Intl.NumberFormat(locale, {
          style: "decimal",
        });
        formattedValue = "RM" + tempFormattedValueMYR.format(Number(value));
        break;
      default:
        formattedValue = new Intl.NumberFormat(locale, {
          style: "currency",
          currency: currency,
        }).format(value);
    }
  } catch (e) {
    console.error(e);
  }

  return formattedValue;
};

/**
 * Utility for when people are mean and they aren't capable
 * of sending ACTUAL boolean values. >:@
 *
 * Add your own true-false pairs below, weirdest one wins (be pendantic):
 * [true, false]
 * ["true", "false"]
 * ["True", "False"]
 * ["TRUE", "FALSE"]
 * [1, 0]
 * ["1", "0"]
 * ["x", ""]
 * ["X", ""]
 *
 *
 * @param {*} value
 * @return {*}  {boolean}
 */
export const sanitizeBoolean = (value: any): boolean => {
  if (
    value === true ||
    value === "true" ||
    value === "True" ||
    value === "TRUE" ||
    value === 1 ||
    value === "1" ||
    value === "x" ||
    value === "X"
  )
    return true;
  return false;
};

//erase decimal numbers in size if present
export const sanitizeSize = (value: string | undefined): string | undefined => {
  if (value) {
    const number = parseInt(value);
    return Number.isNaN(number) ? value : String(number);
  }
};

//Check string if is a number
export const covertStringToInt = (value: string | undefined): number => {
  if (value) {
    const number = parseInt(value);

    if (!Number.isNaN(number)) return number;
  }
  return 0;
};
/**
 * Find document based on type.
 * These are the documents handled in documentsSlice, retrieved in different parts of the website from CMS.
 *
 * @param {Document[]} documentList
 * @param {"string"} type
 * @return {*}  {(Document | undefined)}
 */
export const getDocumentFromType = (
  documentList: Document[],
  type: string
): Document | undefined => {
  return documentList?.find((document) =>
    document.subjectTaxonomy?.find((_) => _?.externalReference === type)
  );
};

//using set to have uniques values
export const cloneDeepSet = (set: Set<string>): Set<string> => {
  return new Set(JSON.parse(JSON.stringify(Array.from(set))));
};

export const isValidUuid = (str: string): boolean => {
  const v4 = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
  return v4.test(str);
};

export const sanitizeString = (str?: string): string => {
  str = str?.replace(/[^a-z0-9áéíóúñü \./,_-]/gim, "");
  return str?.trim() ?? "";
};

export const cleanStringForLabel = (str?: string | null): string => {
  if (!str) return "";
  return (
    str
      // ?.replace(/[^a-z A-Z]/g, "") // remove unwanted characters (only keep letters and spaces)
      ?.replace(/\s{1,}/g, "_") // replace one or more spaces with one underscore
      ?.toUpperCase()
  ); // make uppercase
};

export const mapDuplicatedEmailErrorLabel = (
  errorLabel?: string | null,
  prefix?: string
): string => {
  if (prefix) return prefix + errorLabel;
  return "ERROR" + errorLabel;
};

export const strSplice = (str: string, index: number, count: number, add?: string): string => {
  // We cannot pass negative indexes directly to the 2nd slicing operation.
  if (index < 0) {
    index = str.length + index;
    if (index < 0) {
      index = 0;
    }
  }

  return str.slice(0, index) + (add || "") + str.slice(index + count);
};

/**
 * Font families converter
 * @param {string} font
 * @return {*} {FontFamilies}
 */
export const searchFontFamily = (font: string): FontFamilies | null => {
  switch (font) {
    case "72 Condensed":
      return "72 Condensed";
    case "Bulgari Capitalis":
      return "Bulgari Capitalis";
    case "Bembo MT Pro":
      return "Bembo MT Pro";
    case "Bulgari Capitalis":
      return "Bulgari Capitalis";
    case "Burberry":
      return "Burberry";
    case "Caboto":
      return "Caboto";
    case "Calibri":
      return "Calibri";
    case "Chanel Corpo":
      return "Chanel Corpo";
    case "DIN Pro":
      return "DIN Pro";
    case "DIN Pro Italic":
      return "DIN Pro Italic";
    case "DIN Pro Condensed":
      return "DIN Pro Condensed";
    case "DIN Pro Condensed Italic":
      return "DIN Pro Condensed Italic";
    case "Fenice STD":
      return "Fenice STD";
    case "Futura LT":
      return "Futura LT";
    case "Futura STD":
      return "Futura STD";
    case "Garamond Narrow Plain":
      return "Garamond Narrow Plain";
    case "Helvetica Neue":
      return "Helvetica Neue";
    case "Hurme Geometric":
      return "Hurme Geometric";
    case "Kors Sans":
      return "Kors Sans";
    case "Ray-Ban Sans":
      return "Ray-Ban Sans";
    case "Santral":
      return "Santral";
    case "Selawik":
      return "Selawik";
    case "Sweet Sans Pro":
      return "Sweet Sans Pro";
    case "Versace":
      return "Versace";
    case "Aktiv Grotesk":
      return "Aktiv Grotesk";
    case "Euclid Ignited":
      return "Euclid Ignited";
    case "Franklin Gothic Demi Regular":
      return "Franklin Gothic Demi Regular";
    case "ITC Novarese for Swarovski":
      return "ITC Novarese for Swarovski";
    case "RadiantURWBol":
      return "RadiantURWBol";
    default:
      return null;
  }
};

/**
 * Check if a string has white space
 * @param {string} s
 * @return {boolean}
 */
export function hasWhiteSpace(s: string): boolean {
  return /\s/g.test(s);
}

/**
 * Count white spaces in a string
 * @param {string} s
 * @return {number}
 */
export function countSpaces(s: string): number {
  return s.match(/\s/g)?.length || 0;
}

export const getFamilySize = (attributes: Attribute[]) => {
  const sizeFamily = getAttributeValues(attributes ?? [], "FRAME_SIZE_FAMILY")?.values;
  const sizeFamilyLabelFrame = sizeFamily ? ` (${sizeFamily})` : "";
  return `${sizeFamilyLabelFrame}`;
};

export const checkLengthObject = (element: undefined | any[]): number => {
  if (Array.isArray(element)) return element.length;
  return 0;
};

export const hasAtLeastOneValueFilled = (obj: { [key: string]: any } | undefined): boolean => {
  if (obj)
    for (const key in obj) {
      if (key in obj) {
        const value = obj[key];
        if (value !== undefined && value !== null && value !== "") {
          return true;
        }
      }
    }

  return false;
};
